import type { User } from '@/modules/users/models';
import store from '@/modules/app/store';

export function escapeRegex(string: string) {
  if (!string || string.trim().length === 0) return string;

  return string.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function processUserVisibility() {
  const state = store.state.user;
  const usersList = Object.values<User>(state.users);
  // if there is a search keyword
  if (state.searchable && state.searchKeyword?.length) {
    // hide all users first

    const pattern = new RegExp(escapeRegex(state.searchKeyword), 'i');

    for (let i = 0, l = usersList.length; i < l; i++) {
      const user = usersList[i];
      user.visible = pattern.test(user.nickname);
    }
  } else {
    // show all users
    usersList.forEach((u) => {
      u.visible = true;
    });
  }
}

export function populateUiList() {
  const { users } = store.state.user;
  const list = Object.values(users).filter((u: User) => u.visible);
  store.dispatch('user/setUiListLight', list);
}

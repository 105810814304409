export const TOKEN_URL_REGEX = /\/t\/([^/]{2,})/;
export const LANG_URL_REGEX = /\/l\/([a-zA-Z]{2})/;
export const ROOM_URL_REGEX = /\/ch(?:\/([A-Za-z0-9\-_.]+))(?:\/([A-Za-z0-9\-_.]+))?/;
export interface RoomUrlParts {
  room: string;
  customer: string;
  token: string;
  locale: string;
}
const Global: { value?: RoomUrlParts } = {};

export function parseRoomUrl(roomUrl = window.location.href, byPassCache = false) {
  if (Global.value && !byPassCache) return Global.value;

  // /ch/[{customer}/]{room}[/t/{token}][/l/{language}]
  const url = new URL(roomUrl);
  let fullPath = url.pathname;
  const tokenParts = url.pathname.match(TOKEN_URL_REGEX);
  const langParts = url.pathname.match(LANG_URL_REGEX);
  if (tokenParts) fullPath = fullPath.replace(TOKEN_URL_REGEX, '');
  if (langParts) fullPath = fullPath.replace(LANG_URL_REGEX, '');

  let room = null;
  let customer = null;
  let token = null;
  let locale = null;

  const pathParts = fullPath.match(ROOM_URL_REGEX);
  if (pathParts) {
    if (pathParts[2]) {
      [, customer, room] = pathParts;
    } else {
      [, room] = pathParts;
    }
  }

  if (tokenParts) [, token] = tokenParts;
  if (langParts) [, locale] = langParts;

  Global.value = {
    room,
    customer,
    token,
    locale,
  };

  return Global.value;
}

import storageService from '@/modules/app/services/storageService';
import CONSTANTS from '@/helpers/constants';
import { deepClone, isObject } from '@/helpers/utils';
import { webcamOptions } from '@/modules/multimedia/models/webcamOptions';

const {
  MODULES: {
    NOTIFICATION: { DEFAULT_SETTINGS },
  },
  SETTINGS: { DEFAULT_AUDIO_QUALITY, DEFAULT_DEVICE },
  STORAGE,
} = CONSTANTS;

export default {
  namespaced: true,
  state: {
    // showLocalScreen: storageService.getItem<boolean>(STORAGE.SHOW_LOCAL_SCREEN, true),
    // avoidScreenEcho: storageService.getItem<boolean>(STORAGE.AVOID_SCREEN_ECHO, false),
    // captureSystem: storageService.getItem<boolean>(STORAGE.CAPTURE_SYSTEM, false),
    audioDevice: storageService.getItem(STORAGE.AUDIO_DEVICE) ?? DEFAULT_DEVICE.deviceId,
    audioQuality: storageService.getItem(STORAGE.AUDIO_QUALITY) ?? deepClone(DEFAULT_AUDIO_QUALITY.value),
    notifications: storageService.getItem(STORAGE.NOTIFICATIONS) ?? deepClone(DEFAULT_SETTINGS),
    webcamOptions: webcamOptions,
    // webcamUIOptions: storageService.getItem<WebcamOptions>(STORAGE.WEBCAM_UI_OPTIONS,
    // webcamOptions),
  },
  getters: {
    getSettings: (state) => state,
    getAudioQuality: (state) => state.audioQuality,
    getAudioDevice: (state) => state.audioDevice,
    getNotifications: (state) => state.notifications,
    getWebcamOptions: (state) => state.webcamOptions,
  },
  mutations: {
    setSetting: (state, data) => {
      const { type, value } = data;
      if (isObject(value)) {
        // using empty object to force change detection
        state[type] = Object.assign({}, state[type], value);
      } else state[type] = value;
      storageService.setItem(type, state[type]);
    },
  },
  actions: {
    setSettings: ({ commit }, data: []) => {
      data.forEach((setting) => {
        commit('setSetting', setting);
      });
    },
  },
};

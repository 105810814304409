import storageService from '@room/ui.sdk/core/services/storage';
import CONSTANTS from '@/helpers/constants';

const { STORAGE } = CONSTANTS;

export default {
  namespaced: true,
  state: {
    src: null,
    sessionId: null,
    userId: null,
    streamId: null,
    showLocalScreen: storageService.getItem<boolean>(STORAGE.SHOW_LOCAL_SCREEN, true),
    avoidScreenEcho: storageService.getItem<boolean>(STORAGE.AVOID_SCREEN_ECHO, false),
    captureSystem: storageService.getItem<boolean>(STORAGE.CAPTURE_SYSTEM, false),
    screenStatus: {
      active: false,
      loading: false,
    },
  },
  getters: {
    // X: (state) => state.X,
  },
  actions: {
    set: ({ state }, data: any) => {
      Object.assign(state, data);
    },
    setScreenStatus: (
      { state },
      data: {
        active: boolean;
        loading: boolean;
        access: boolean;
      },
    ) => {
      Object.assign(state.screenStatus, data);
    },
  },
};

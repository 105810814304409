import type { ActionContext } from 'vuex';
import type { SortedUserRepo, User, UserRepo, UserStoreState } from '@/modules/users/models';
import { AllUsersStatus, ListFilterType, UserDevices, UserDeviceStatus } from '@/modules/users/models';
import { ROLE } from '@/modules/accessControl/roles';
import CONSTANTS from '@/helpers/constants';
import { deepExtend } from '@/helpers/utils';
import type { ClientsVisibility } from '@back/room-sdk/dist/packages/models/src/room/user';
import UAParser from 'ua-parser-js';
import { isMobileDevice } from '@/helpers/device';
import { processUserVisibility, populateUiList } from '@/modules/users/services/userVisibility';
import debounce from 'lodash.debounce';
import { hasFeatureFlag } from '@room/ui.sdk/core/services/featureFlag';
import { FEAT_USER_SORT } from '@/modules/users/featureFlags';
import { BOARD_TYPE } from '@/modules/whiteboard.v1/boardType';
import store from '@/modules/app/store';

const { USER } = CONSTANTS.MODULES;
const SuperRoles = [ROLE.Admin, ROLE.Owner, ROLE.Support];
const DefaultSortedList = (): SortedUserRepo => {
  if (hasFeatureFlag(FEAT_USER_SORT) === 'none') return { main: {} };

  const stateHigh = {};
  const stateLow = {};

  SuperRoles.forEach((role: string) => {
    stateHigh[role] = {};
  });

  [ROLE.Operator, ROLE.Presenter].forEach((role: string) => {
    stateLow[`${role}-active`] = {};
    stateLow[role] = {};
  });

  return {
    ...stateHigh,
    hands: {},
    ...stateLow,
    ...{
      active: {},
      [ROLE.Member]: {},
      [ROLE.Guest]: {},
    },
  };
};

function defaultState(): UserStoreState {
  return {
    me: null,
    isPublic: false,
    uiListLight: [],
    handsUpList: [],
    activeList: [],
    searchable: false,
    users: {} as UserRepo,
    usersCount: 0,
    // numberOfGuestsAndMembers: 0,
    visibility: 'visible',
    searchKeyword: null,
    filterType: 'none',
    allUsersStatus: AllUsersStatus.UNKNOWN,
    activeStateCount: 0,
  };
}

const debouncedUpdateUI = debounce(() => {
  processUserVisibility();
  populateUiList();
}, 1000);

export function addToList(user: User, list: User[]) {
  if (!list.find((u: User) => u.sessionId === user.sessionId)) list.push(user);
}

function removeFromList(user: User, list: User[]) {
  const index = list.findIndex((u: User) => u.sessionId === user.sessionId);
  if (index !== -1) list.splice(index, 1);
}

export function hasActiveState(user: User) {
  return (
    [UserDevices.MIC, UserDevices.WEBCAM, UserDevices.DESKTOP, BOARD_TYPE.BOARD]
      .map((d) => [UserDeviceStatus.ON, UserDeviceStatus.ACTIVE].includes(user.state[d]))
      .filter((s) => !!s).length !== 0
  );
}

function resetListFilterType(state: UserStoreState) {
  if (!state.handsUpList.length && state.filterType === ListFilterType.HAND) state.filterType = ListFilterType.NONE;
  if (!state.activeList.length && state.filterType === ListFilterType.ANY) state.filterType = ListFilterType.NONE;
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getMe: (state: UserStoreState): User => state.users[state.me] ?? ({ role: ROLE.Guest } as User),
    getUsers: (state: UserStoreState): UserRepo => state.users,
    getUser: (state: UserStoreState) => (userId: number) => state.users[userId] || null,
    getUserById: (state: UserStoreState) => (userId: number) => state.users[userId] || null,
    getVisibility: (state: UserStoreState) => state.visibility,
  },
  actions: {
    // only used to initially setting the me user and updating nickname
    setMe: async ({ getters, state, dispatch }, user: User) => {
      // set the state.me to new userId
      if (user.id) {
        state.me = user.id;
        if (!getters.getUserById(user.sessionId)) await dispatch('addUser', user);
      } else {
        // get the me user
        const me = getters.getMe;
        if (me) {
          Object.assign(me, user);
        }
      }
    },
    onLeave: async (context: ActionContext<UserStoreState, any>) => {
      context.state = defaultState();
    },
    removeUser: ({ getters, state }, id) => {
      const user = getters.getUserById(id);
      if (user) {
        removeFromList(user, state.handsUpList);
        removeFromList(user, state.activeList);
        removeFromList(user, state.uiListLight);
        delete state.users[id];
        resetListFilterType(state);
        debouncedUpdateUI();
      }
    },
    handUp: ({ getters, state }, userId: string) => {
      const user = getters.getUserById(userId);
      if (!user) return;

      if (!user.state) user.state = {};

      user.state.hand = UserDeviceStatus.ON;
      addToList(user, state.handsUpList);
      debouncedUpdateUI();
    },
    handDown: ({ getters, state }, userId: string) => {
      const user = getters.getUserById(userId);
      user.state.hand = UserDeviceStatus.OFF;
      removeFromList(user, state.handsUpList);
      debouncedUpdateUI();
      resetListFilterType(state);
    },
    onManageSpeaking: ({ getters }, { id, status }) => {
      const user = getters.getUserById(id);

      if (!user) return;

      user.state.mic = status;
    },
    setMyRole: ({ dispatch, getters }, role: string) => {
      const user = getters.getMe;
      dispatch(USER.MUTATIONS.SET_USER_ROLE, { user, role }, { root: true });
    },
    setUserRole: ({ state }, { user, role }) => {
      // TODO check user list visibility too
      user.role = role;
      debouncedUpdateUI();
    },
    updateUser: ({ getters, state }, data: User): User => {
      const user = getters.getUserById(data.sessionId || data.id);
      if (!user) return null;

      deepExtend(user, data);
      if (hasActiveState(user)) addToList(user, state.activeList);
      else removeFromList(user, state.activeList);
      debouncedUpdateUI();
      resetListFilterType(state);
      return user;
    },
    resetUsers: ({ state, dispatch }) => {
      state.users = {};
      state.handsUpList = [];
      state.activeList = [];
      state.usersCount = 0;
      state.me = null;
    },
    insertUsers: ({ state, dispatch, getters }, users: User[]) => {
      users.forEach(async (user) => {
        if (!getters.getUserById(user.sessionId)) await dispatch('addUser', user);
      });

      debouncedUpdateUI();
    },
    addUser: ({ state }, user: User) => {
      // don't inject these users into the list we don't care about them and don't want to show or count them
      if ([ROLE.Recorder, ROLE.Agent].includes(user.role)) return;

      user.visible = true;
      user.isMobileDevice = isMobileDevice(user.platform);
      user.platform = UAParser(user.platform);
      user.originalRole = user.originalRole ?? user.role;

      state.users[user.id] = user;

      // check if users limit is at threshold
      // if (state.uiListLight.length < store.state.system.usersState?.clientsLimitNumber) {
      //   // check if user is not already in the list
      //   if (!state.uiListLight.find((u) => u.tabId === user.tabId)) {
      //     state.uiListLight.push(user);
      //   }
      // }
      if (user.state.hand === UserDeviceStatus.ON) addToList(user, state.handsUpList);
      if (hasActiveState(user)) addToList(user, state.activeList);

      debouncedUpdateUI();
    },
    setUsersVisibility({ state }, visibility: ClientsVisibility) {
      state.visibility = visibility;
    },
    setIsPublic({ state }, isPublic: boolean) {
      state.isPublic = isPublic;
    },
    setUsersCount({ state }, count: number) {
      state.usersCount = count;
    },
    setUiListLight({ state }, list: User[]) {
      let users = [...list];
      // only splice if full user list was not loaded
      if (
        state.allUsersStatus === AllUsersStatus.CAN_LOAD &&
        list.length > store.state.system.usersState.clientsLimitNumber
      )
        users = list.splice(0, store.state.system.usersState.clientsLimitNumber);
      state.uiListLight = users;
    },
    setSearchable({ state }, status: boolean) {
      state.searchable = status;
    },
    setFilterType({ state }, filterType: string) {
      state.filterType = filterType;
    },
    setAllUsersStatus({ state }, status: number) {
      state.allUsersStatus = status;
    },
    addToHandsUpList({ state }, user: User) {
      addToList(user, state.handsUpList);
    },
    addToActiveList({ state }, user: User) {
      addToList(user, state.activeList);
    },
    removeFromHandsUpList({ state }, user: User) {
      removeFromList(user, state.handsUpList);
    },
    removeFromActiveList({ state }, user: User) {
      removeFromList(user, state.activeList);
    },
    addActiveStream({ state }, count: number) {
      state.activeStateCount += count;
    },
    removeActiveStream({ state }, count: number) {
      state.activeStateCount -= count;
      if (state.activeStateCount < 0) state.activeStateCount = 0;
    },
  },
};

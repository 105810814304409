import { ErrorIcon, InfoIcon, WarningIcon, DoneIcon } from '@/assets/icons';
import { Alert, type ToastOptions } from '@/components/toasts/types';

interface State {
  seed: number;
  openCount: number;
  toasts: ToastOptions[];
}

const IconMap = {
  [Alert.INFO]: InfoIcon,
  [Alert.WARNING]: WarningIcon,
  [Alert.ERROR]: ErrorIcon,
  [Alert.SUCCESS]: DoneIcon,
};

export default {
  namespaced: true,
  state: {
    seed: 0,
    openCount: 0,
    toasts: [],
  } as State,

  actions: {
    showToast: ({ state }: { state: State }, options: ToastOptions) => {
      const newSeed = state.seed + 1;
      const id = options.id ?? `toast_${newSeed}`;

      const newToast = { id, ...options };

      if (IconMap[options.type]) newToast.icon = IconMap[options.type];

      if (!options.duration) newToast.duration = 5; // Default

      state.seed = newSeed;
      state.openCount = state.openCount + 1;
      state.toasts = [...state.toasts, newToast];
    },

    closeToast: ({ state }: { state: State }, id: string) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== id);
      state.openCount = state.openCount - 1;
    },

    closeOldest: ({ state }: { state: State }) => {
      state.toasts = state.toasts.slice(0, -1);
      state.openCount = state.openCount - 1;
    },

    closeAll: ({ state }: { state: State }) => {
      state.toasts = [];
      state.openCount = 0;
    },
  },
};

import type { RoomMedia } from '@/modules/multimedia/models/roomMedia';
import type * as RoomTypes from '@back/room-sdk';
import type { ActionContext } from 'vuex';

interface State {
  roomMedia: RoomMedia;
  roomMediaType: string;
  receivedMedia: RoomTypes.media.OpenRequest;
  isPublisher: boolean;
  active: boolean;
}

const DefaultRoomMedia = () => {
  return {
    source: null,
    maximized: false,
    isLocal: false,
    id: 0,
    title: null,
    downloadable: true,
    type: null,
    streamId: null,
  } as RoomMedia;
};

export default {
  namespaced: true,
  state: {
    roomMedia: DefaultRoomMedia(),
    roomMediaType: 'audio',
    receivedMedia: null,
    isPublisher: false,
    active: false,
  } as State,

  getters: {
    getRoomMedia: (state: State) => state.roomMedia,
    getRoomMediaType: (state: State) => state.roomMediaType,
    getReceivedMedia: (state: State) => state.receivedMedia,
    getIsPublisher: (state: State) => state.isPublisher,
    getIsActive: (state: State) => state.active,
  },
  actions: {
    setRoomMedia: (context: ActionContext<State, any>, roomMedia: Partial<RoomMedia>) => {
      Object.assign(context.state.roomMedia, { isLocal: false }, roomMedia ?? DefaultRoomMedia());
    },
    setRoomMediaType: ({ state }, roomMediaType) => {
      state.roomMediaType = roomMediaType;
    },
    setReceivedMedia: ({ state }, data) => {
      state.receivedMedia = data;
    },
    toggle: ({ state }, status) => {
      state.active = status;
    },
    toggleIsPublisher: ({ state }, status) => {
      state.isPublisher = status;
    },
  },
};

export const NOTIFICATION_TYPES = {
  USER_JOINED: 'user_joined',
  USER_LEFT: 'user_left',
  HAND_RAISE: 'hand_raise',
  CHAT_MESSAGE: 'chat_message',
  GET_KICKED: 'get_kicked',
  MEDIA_ERROR: 'media_error',
  NETWORK_ERROR: 'network_error',
};

// TODO get these from SDK
// TODO mic has no quality setting!!!
enum MicQuality {
  Medium = 'medium',
  Good = 'good',
  VeryGood = 'veryGood',
}

const AUDIO_QUALITIES = [
  { bitrate: 10, label: 'setting.audioQualities.medium', value: MicQuality.Medium },
  { bitrate: 25, label: 'setting.audioQualities.good', value: MicQuality.Good },
  { bitrate: 40, label: 'setting.audioQualities.veryGood', value: MicQuality.VeryGood },
];
const RECORD = {
  ACL: {
    ALLOWED: 'record_allowed',
  },
};

export const MULTIMEDIA = {
  ACL: {
    ACTIVE_MIC: 'active_mic',
    ACTIVE_WEBCAM: 'active_webcam',
    ACTIVE_SCREEN_SHARE: 'active_screen_share',
    VIDEOGRID_TOGGLE_MAXIMIZE: 'videogrid_toggle_maximize',
    MEDIABLOCK_TOGGLE_MAXIMIZE: 'mediablock_toggle_maximize',
  },
};

const CONSTANTS = {
  MODULES: {
    SYSTEM: {
      ACTIONS: {
        ROOM_CLOSE: 'close-room',
        ROOM_CLOSING: 'room-closing',
        CLEAR_AUTO_LOGIN_STORAGE: 'system/clearAutologinStorage',
        SET_CONNECTION_STATUS: 'system/setConnectionStatus',
        SET_THERE_IS_ACTIVE_AUDIO: 'system/setThereIsActiveAudio',
      },
      GETTERS: {
        ROOM_INFO: 'system/getRoomInfo',
        LOGIN_INFO: 'system/getLoginInfo',
        GET_SYSTEM_INFO: 'system/getSystem',
        WAITING_FOR_OPERATOR: 'system/waitingForOperator',
        GET_NUMBER_OF_USER_IN_QUEUE: 'system/getNumberOfUsersInQueue',
        GUEST_LOGIN_ENABLED: 'system/guestLoginEnabled',
        LOGIN_PENDING: 'system/loginPending',
        GET_LOGIN_STATUS: 'system/getLoginStatus',
        AUTO_LOGIN: 'system/getAutologin',
        GET_ROOM_STARTED_TIME: 'system/getRoomStartedTime',
        GET_ACTIVE_PAGE: 'system/getActivePage',
        GET_CONNECTION_STATUS: 'system/getConnectionStatus',
        GET_USER_HAS_INTERACTED: 'system/getUserHasInteractedWithPage',
        GET_THERE_IS_ACTIVE_AUDIO: 'system/getThereIsActiveAudio',
      },
      MUTATIONS: {
        SET_SYSTEM_INFO: 'system/setSystemInfo',
        SET_AUTO_LOGIN: 'system/setAutologinStorage',
        GET_AUTO_LOGIN: 'system/getAutologinStorage',
        CLEAR_SYSTEM: 'system/clearSystemInfo',
        TOGGLE_THEME: 'toggleMode',
      },
      ACL: {
        CLOSE_ROOM: 'close_room',
        LAYOUT: 'layout',
      },
      STORAGE: {
        LAST_EXIT: 'last_exit',
      },
    },
    LAYOUT: {
      NAME: 'layout',
    },
    CHAT: {
      NAME: 'chat',
      ACTIONS: {
        UPDATE_MESSAGE: 'updateMessage',
      },
      GETTERS: {
        PINNED: 'getPinnedMessage',
        DISABLED: 'getDisabled',
        GET_MESSAGES: 'getMessages',
        GET_IS_PRIVATE_MODE: 'getIsPrivateMode',
        GET_MESSAGE_BY_ID: 'getMessageById',
      },
      MUTATIONS: {
        PIN: 'onPin',
        UNPIN: 'onUnpin',
        ON_UNMUTE_USER: 'onUnMuteUser',
        ON_UNMUTE_ALL_USERS: 'onUnMuteAllUsers',
        SET_CHAT_STATUS: 'setChatStatus',
        ADD_MESSAGE: 'addMessage',
        ON_UPDATE_MESSAGE: 'onUpdateMessage',
      },
      ACL: {
        PRIVATE_MODE: 'chat_private_mode',
        DISABLE_CHAT: 'chat_disable_chat',
        MUTE_USER: 'chat_mute_user',
        PIN_MESSAGE: 'chat_pin_message',
        CLOSE: 'chat_close',
        TOGGLE_MAXIMIZE: 'toggle_maximize',
      },
    },
    USER: {
      NAME: 'user',
      ACTIONS: {
        INFO: 'info',
        KICK: 'kick',
        BAN: 'ban',
        HAND: 'hand',
        HANDS_DOWN: 'hands-down',
        UNBAN_ALL: 'unban-all',
        ACCESS: 'access',
      },
      GETTERS: {
        GET_USER: 'user/getUser',
        GET_USERS: 'user/getUsers',
        GET_USER_BY_ID: 'user/getUserById',
        GET_AUDIO_STATUS: 'getAudioStatus',
        GET_VIDEO_STATUS: 'getVideoStatus',
        GET_IS_USERS_VISIBLE: 'user/getIsUsersVisible',
        GET_HANDS_UP_COUNT: 'user/getHandsUpCount',
      },
      MUTATIONS: {
        ME: 'user/setMe',
        MY_ROLE: 'user/setMyRole',
        NICKNAME: 'user/onChangeNickname',
        USER_LEAVE: 'user/removeUser',
        USER_REMOVE: 'user/removeUser',
        ADD_USER: 'user/addUser',
        UPDATE_USER: 'user/updateUser',
        RAISE_HAND: 'user/raiseHand',
        SET_USER_RAISE_HAND: 'user/setUserRaiseHand',
        RAISE_HAND_USERS: 'user/handUp',
        HAND_DOWN: 'user/handDown',
        DOWN_HAND_ALL_USERS: 'user/handsDownAllUser',
        ON_ACCESS_OTHER_USER_DEVICE: 'user/onAccessOtherUserDevice',
        ON_MANAGE_SPEAKING: 'user/onManageSpeaking',
        SET_USER_ROLE: 'user/setUserRole',
        SET_IS_USERS_VISIBLE: 'user/setIsUsersVisible',
      },
    },
    WEBRTC: {
      NAME: 'webrtc',
      ACTIONS: {
        START_STATS: 'wrtc-start-stats',
        STOP_STATS: 'wrtc-stop-stats',
        STATS: 'wrtc-stats',
        PING: 'ding',
        PRODUCER_ADDED: 'wrtc-producer-added',
      },
      ACCESS_ACTIONS: {
        ENABLE: 'enable',
        DISABLE: 'disable',
        REJECT: 'reject',
      },
      GETTERS: {
        GET_MIC_STATUS: 'rtc/getMicStatus',
        GET_SPEAKER_STATUS: 'rtc/getSpeakerStatus',
        GET_SCREEN_STATUS: 'rtc/getScreenStatus',
        GET_AUDIO_SRC: 'rtc/getAudioSRC',
        GET_MIC_SRC: 'rtc/getMicSource',
        GET_VIDEO_SRC: 'rtc/getVideoSRC',
        GET_SCREEN_SRC: 'rtc/getScreenSRC',
        GET_WEBCAM_STATUS: 'rtc/getWebcamStatus',
      },
      MUTATIONS: {
        SET_AUDIO_SRC: 'rtc/setAudioSrc',
        DELETE_AUDIO_SRC: 'rtc/deleteAudioSrc',
        SET_VIDEO_SRC: 'rtc/setVideoSrc',
        SET_SCREEN_SRC: 'rtc/setScreenSrc',
        DELETE_VIDEO_SRC: 'rtc/deleteVideoSrc',
        SET_SPEAKER_STATUS: 'rtc/setSpeakerStatus',
        SET_MIC_STATUS: 'rtc/setMicStatus',
        SET_WEBCAM_STATUS: 'rtc/setWebcamStatus',
      },
      ACL: {
        SHARE_DESKTOP: 'share_desktop',
      },
    },
    NOTIFICATION: {
      PERMISSIONS: {
        DEFAULT: 'default',
        GRANTED: 'granted',
        DENIED: 'denied',
        NOT_SUPPORTED: 'notsupported',
      },
      DIRECTIONS: { AUTO: 'auto', LTR: 'ltr', RTL: 'rtl' },
      TYPES: NOTIFICATION_TYPES,
      DEFAULT_SETTINGS: {
        [NOTIFICATION_TYPES.USER_JOINED]: [false, false], // [popup, sound]
        [NOTIFICATION_TYPES.USER_LEFT]: [false, false],
        [NOTIFICATION_TYPES.HAND_RAISE]: [false, false],
        [NOTIFICATION_TYPES.CHAT_MESSAGE]: [true, false],
        [NOTIFICATION_TYPES.GET_KICKED]: [true, true],
        [NOTIFICATION_TYPES.MEDIA_ERROR]: [true, true],
        [NOTIFICATION_TYPES.NETWORK_ERROR]: [true, true],
      },
    },
    WHITEBOARD: {
      /**
       * @deprecated use WHITEBOARD_ACL_ACCESS
       */
      ACL: {
        WHITEBOARD: 'access_whiteboard',
      },
    },
    FILE: {
      NAME: 'file',
      ACTIONS: {
        GET_FILES: 'files/get-files',
        DELETE_FILE: 'files/deleteFile',
        UPDATE_FILE: 'files/updateFile',
        SET_IS_FILE_UPLOADED: 'files/setIsFileUploaded',
        SET_IS_FILE_ONPROGRESS: 'files/setIsFileOnProgress',
        SET_FILE_TOKEN: 'files/setFileToken',
        SET_FILES: 'files/setFiles',
        SET_UPLOAD_INFO: 'files/setUploadInfo',
        SET_RECENTLY_UPLOADED_LINK: 'files/setRecentlyUploadedLink',
        SHOW_FILE_UPLOAD_POPUP: 'files/showFileUploadPopup',
        SET_SELECTED_FILE: 'files/setSelectedFile',
      },
    },
    SLIDE: {
      NAME: 'slide',
      GETTERS: {
        GET_SLIDE: 'slide/getSlide',
        GET_PREVIEW_SLIDE: 'slide/getSlidePreview',
      },
      MUTATIONS: {
        SET_SLIDE: 'slide/setSlide',
        SET_SLIDE_PREVIEW: 'slide/setSlidePreview',
      },
    },
    MEDIA_PLAYER: {
      NAME: 'media',
      EVENTS: {
        OPEN: 'open',
        CLOSE: 'close',
        PLAY: 'play',
        PAUSE: 'pause',
        SEEK: 'seek',
        VOLUME: 'volume',
        LOOP: 'loop',
        ERROR: 'media-error',
        READY: 'ready',
        MEDIA_TYPE: 'mediaType',
      },
      GETTERS: {
        ROOM_MEDIA: 'media/getRoomMedia',
        ROOM_MEDIA_TYPE: 'media/getRoomMediaType',
        GET_RECEIVE_MEDIA: 'media/getReceivedMedia',
        GET_IS_PUBLISHER: 'media/getIsPublisher',
        GET_IS_ACTIVE: 'media/getIsActive',
      },
      ACTIONS: {
        SET_ROOM_MEDIA: 'media/setRoomMedia',
        SET_ROOM_MEDIA_TYPE: 'media/setRoomMediaType',
        SET_RECEIVED_MEDIA: 'media/setReceivedMedia',
        TOGGLE_IS_PUBLISHER: 'media/toggleIsPublisher',
        TOGGLE: 'media/toggle',
      },
      ACL: {
        PLAY_MEDIA: 'play_media',
      },
    },
    SETTING: {
      GETTERS: {
        GET_AUDIO_QUALITY: 'setting/getAudioQuality',
        GET_AUDIO_DEVICE: 'setting/getAudioDevice',
        GET_NOTIFICATIONS: 'setting/getNotifications',
      },
      MUTATIONS: {
        SET_SETTING: 'setting/setSetting',
      },
    },
    DIALOG: {
      GETTERS: {
        GET_DIALOGS: 'dialog/getDialogs',
      },
      MUTATIONS: {
        SHOW_DIALOG: 'dialog/showDialog',
        HIDE_DIALOG: 'dialog/hideDialog',
        RESET_DIALOGS: 'dialog/resetDialogs',
      },
    },
    LOCALIZATION: {
      GETTERS: {
        GET_LANGUAGE: 'localization/getLanguage',
      },
      ACTIONS: {
        SET_LANGUAGE: 'localization/setLocale',
      },
    },
    POOL: {
      NAME: 'pool',
      STORAGE: 'roomPools',
      GETTERS: {
        IS_ACTIVE: 'pool/isActive',
        GET_POOL_TO_EDIT: 'pool/getPoolToEdit',
        GET_QUESTION: 'pool/getQuestion',
        GET_POOLS: 'pool/getPools',
        GET_POOL_TO_VIEW: 'pool/getPoolToView',
      },
      ACTIONS: {
        RESET_POOL: 'pool/reset',
        SET_QUESTION: 'pool/setQuestion',
        SAVE_QUESTION: 'pool/saveQuestion',
        EDIT_POOL: 'pool/editPool',
        VIEW_POOL: 'pool/viewPool',
        SET_POOLS: 'pool/setPools',
        DELETE_POOL: 'pool/deletePool',
        SET_POOL: 'pool/setPool',
      },
      ACL: {
        CREATE_POOL: 'manage_own_pools',
      },
    },
    RECORD,
    MULTIMEDIA,
  },
  MESSAGES: {
    REMOVE_ALL_MESSAGES: 'message.remove_all_messages',
    REMOVE_MESSAGE: 'message.remove_message',
    REMOVE_FILE: 'file.remove_file',
    KICK_USER: 'message.kick_user',
    BAN_USER: 'message.kick_ban_user',
  },
  STORAGE: {
    NICKNAME: 'nickname',
    LOGIN_INFO: 'system_login_info',
    SHOW_LOCAL_VIDEO: 'showLocalVideo',
    SHOW_LOCAL_SCREEN: 'showLocalScreen',
    SHOW_LOCAL_VIDEO_MIRROR: 'showLocalVideoMirror',
    AVOID_SCREEN_ECHO: 'avoidScreenEcho',
    CAPTURE_SYSTEM: 'captureSystem',
    AUDIO_QUALITY: 'audioQuality',
    VIDEO_QUALITY: 'videoQuality',
    AUDIO_DEVICE: 'audioDevice',
    VIDEO_DEVICE: 'videoDevice',
    NOTIFICATIONS: 'notifications',
    BLUR_WEBCAM_BG: 'blurWebcamBg', // TODO kept for backward compatibility
    WEBCAM_BG_IMAGE: 'webcamBgImage', // TODO kept for backward compatibility
    WEBCAM_OPTIONS: 'webcamOptions',
    WEBCAM_UI_OPTIONS: 'webcamOptions',
    WHITEBOARD_VERSION: 'whiteboardVersion',
  },
  SETTINGS: {
    STORAGE: 'settings',
    ENTRIES: {
      APP_ID: 'app-id',
      VERSION: 'version',
      NICKNAME: 'nickname',
      AUDIO_DEVICE: 'audio-device',
      VIDEO_DEVICE: 'video-device',
      AUDIO_QUALITY: 'audio-quality',
      VIDEO_QUALITY: 'video-quality',
      SHOW_LOCAL_VIDEO: 'show-local-video',
      SHOW_LOCAL_SCREEN: 'show-local-screen',
      AVOID_SCREEN_ECHO: 'avoid-screen-echo',
      CAPTURE_SYSTEM_AUDIO: 'capture-system-audio',
      NOTIFICATIONS: 'notifications',
    },
    AUDIO_QUALITIES,

    DEFAULT_AUDIO_QUALITY: AUDIO_QUALITIES[0],
    DEFAULT_DEVICE: { name: 'Default', deviceId: null },
  },
  BREAK_POINTS: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  },
  OS: {
    ANDROID: 'Android',
    WINDOWS: 'Windows',
    IOS: 'iOS',
  },
  ORIENTATION: {
    PORTRAIT: '(max-aspect-ratio: 13/9)',
    LANDSCAPE: '(min-aspect-ratio: 13/9)',
  },
  // BROWSERS: {
  //   CHROME: { name: 'Chrome', supportedVersion: 61 },
  //   FIREFOX: { name: 'firefox', supportedVersion: 60 },
  //   OPERA: { name: 'opera', supportedVersion: 48 },
  //   SAFARI: { name: 'safari', supportedVersion: 12.1 },
  //   IOS_SAFARI: { name: 'ios_saf', supportedVersion: 12.3 },
  //   'MOBILE SAFARI': { name: 'Mobile Safari', supportedVersion: 12.3 },
  //   SAMSUNG: { name: 'samsung ', supportedVersion: 8.2 },
  //   EDGE: { name: 'edge', supportedVersion: 61 },
  //   'ANDROID BROWSER': { name: 'Android Browser', supportedVersion: 0 },
  // },
  LOGIN_STATUS: {
    SUCCESS: 'success',
    FAILED: 'failed',
    UN_AUTHORIZED: 'unauthorized',
    EXPIRED_TOKEN: 'expired_token',
    INVALID_URL: 'invalid_url',
    INACTIVE_ROOM: 'inactive_room',
    USER_LIMIT: 'user_limit',
    USER_PUBLIC_LIMIT: 'user_public_limit',
    USER_BANNED: 'failed_banned',
    IFRAME_NOT_ALLOWED: 'iframe_not_allowed',
    NO_ROOM_SERVICE: 'no_room_service',
    NOT_STARTED_SERVICE: 'not_started_service',
    EXPIRE_SERVICE: 'expire_service',
    INACTIVE_SERVICE: 'inactive_service',
    SERVICE_NOT_FOUND: 'service_not_found',
    USER_PRIVATE_DUPLICATE: 'user_private_duplicate',
    NO_ACCESS: 'no_access',
    NO_CUSTOMER: 'no_customer',
    INVALID_ROOM: 'invalid_room',
    INVALID_CHANNEL: 'invalid_channel',
    GUEST_NOT_ACTIVE: 'guest_not_active',
    GUEST_USER_LIMIT: 'guest_user_limit',
    INVALID_CREDENTIALS: 'invalid_credentials',
    USER_NOT_FOUND: 'user_not_found',
    INVALID_LOGIN_TYPE: 'invalid_login_type',
    DISABLED_USER: 'disabled_user',
    EXPIRED_USER: 'expired_user',
    INTERNAL_SERVER_ERROR: 'internal_server_error',
    INVALID_TOKEN: 'invalid_token',
    SERVICE_USERS_LIMIT: 'service_users_limit',
  },
  ERROR_TYPES: {
    SDK: 'sdk',
    LOCAL: 'local',
    XHR: 'xhr',
  },
};

export enum ORIENTATION {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export default Object.freeze(CONSTANTS);

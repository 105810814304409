enum Width {
  'full' = '100%',
  'xl2' = '800px',
  'xl' = '700px',
  'lg' = '600px',
  'md' = '500px',
  'sm' = '400px',
  'xs' = '300px',
}

export default Width;

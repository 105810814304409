import type { Language } from '@room/ui.modules/i18n/language';

export const SupportedLocales: Language[] = [
  { name: 'Farsi', locale: 'fa', direction: 'rtl' },
  { name: 'English', locale: 'en', direction: 'ltr' },
  { name: 'Arabic', locale: 'ar', direction: 'rtl' },
  { name: 'Russian', locale: 'ru', direction: 'ltr' },
];

export const DEFAULT_LANGUAGE: Language = SupportedLocales[0];

/**
 * @deprecated
 */
export { WEBRTC_ERROR as WRTC_ERROR, STREAM_TYPE } from '@room/ui.sdk/multimedia/consts';

export enum CameraQuality {
  Medium = 'medium',
  Good = 'good',
  VeryGood = 'veryGood',
  Auto = 'auto',
}

// height, maxBitrate
export const CameraResolutions = {
  [CameraQuality.Medium]: [240, 120000],
  [CameraQuality.Good]: [480, 600000],
  [CameraQuality.VeryGood]: [720, 900000],
  // [CameraQuality.Auto]: [null, 900000],
};

export const VIDEO_QUALITIES = [
  // { label: 'setting.videoQualities.auto', value: CameraQuality.Auto },
  { label: 'setting.videoQualities.medium', value: CameraQuality.Medium },
  { label: 'setting.videoQualities.good', value: CameraQuality.Good },
  { label: 'setting.videoQualities.veryGood', value: CameraQuality.VeryGood },
];
export const DEFAULT_VIDEO_QUALITY = CameraQuality.Medium;

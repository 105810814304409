enum LayoutBlock {
  CHAT = 'chat',
  USER = 'user',
  MEDIA = 'media',
  VIDEO_GRID = 'videoGrid',
  WEBCAM = 'videoGrid',
  WHITEBOARD = 'whiteBoard',
  SLIDESHOW = 'slideShow',
  SCREEN_SHARE = 'screenShare',
  TOOLBAR = 'toolbar',
}

export default LayoutBlock;

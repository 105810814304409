export function isObject(obj: any) {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
}

/**
 * compare 2 objects
 * @param item1
 * @param item2
 */
export function isEqual(item1: any, item2: any) {
  if (isObject(item1) && isObject(item2)) return JSON.stringify(item1) === JSON.stringify(item2);

  return item1 === item2;
}

export function isString(value?: any) {
  return typeof value === 'string' || value instanceof String;
}

export function isBoolean(variable: any) {
  return typeof variable == 'boolean';
}

export function isNumber(data: any) {
  return (
    !Number.isNaN(data) &&
    typeof data !== 'object' &&
    data !== Number.POSITIVE_INFINITY &&
    data !== Number.NEGATIVE_INFINITY
  );
}

import { RECORDER_PART } from '@/modules/recorderCloud/isRecorder';
import { httpClientIntegration, reportingObserverIntegration } from '@sentry/integrations';

export default {
  environment: `${import.meta.env.VITE_ENV}${RECORDER_PART}`,
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  beforeSend(event) {
    return event;
  },
  integrations: [
    httpClientIntegration({
      failedRequestStatusCodes: [400, [402, 499], [500, 599]],
    }),
    reportingObserverIntegration({ types: ['crash', 'deprecation'] }),
  ],
  // sampleRate is used for Error events and tracesSampleRate is used for Transaction events which are used for performance monitoring.
  // Set tracesSampleRate to 1.0 to capture 100%
  tracesSampleRate: 0.1,
  // Setting an SDK sample rate limits visibility into the source of events.
  // Also effects UserFeedback events
  // Setting a rate limit for your projectRepresents your service in Sentry and allows you to scope events to a distinct application.
  // (which only drops events when volume is high) may better suit your needs.
  sampleRate: 0.8,
  release: APP_VERSION,
  logErrors: true,
  attachStacktrace: true,
  autoSessionTracking: true,
  sendDefaultPii: true,
  sendClientReports: false,
};

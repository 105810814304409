import type { Language } from '@room/ui.modules/i18n/language';
import { detectLocale, findLanguage } from '@room/ui.modules/i18n/language';

export { findLanguage, setupDom, detectLocale, STORAGE_KEY } from '@room/ui.modules/i18n/language';

const Global: { value: Language } = { value: findLanguage(detectLocale()) };

export function saveGlobalLanguage(lang: Language) {
  Global.value = lang;
}

export function getGlobalLanguage(): Language {
  return Global.value;
}

import { shallowRef } from 'vue';
import Width from '@/helpers/widthEnum';
import type { DialogOptions } from '@/components/dialog/store/model';
import { ClearIcon, DoneIcon } from '@/assets/icons';

const DefaultOptions: DialogOptions = {
  id: '',
  draggable: false,
  show: true,
  body: null,
  data: null,
  size: Width.md,
  overlay: false,
  closeable: true,
  escapable: false,
  onClose: null,
  fullScreen: false,
  overlayColor: '',
  confirmBtnIcon: DoneIcon,
  denyBtnIcon: ClearIcon,
  className: '',
  zIndex: null,
};

export default {
  namespaced: true,
  state: {} as Record<string, DialogOptions>,

  getters: {
    getDialogs: (state) => state,
    getDialog: (state) => (id) => state[id] ?? null,
  },

  mutations: {
    showDialog: (state: Array<DialogOptions>, options: DialogOptions) => {
      options.show = true;
      options.body = shallowRef(options.body);
      state[options.id] = { ...DefaultOptions, ...options };
    },

    hideDialog: (state: Array<DialogOptions>, id: string) => {
      delete state[id];
    },

    resetDialogs: (state) => {
      // TODO can I do state = {};
      Object.keys(state).forEach((id) => {
        delete state[id];
      });
    },
  },
};

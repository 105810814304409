import { createStore } from 'vuex';
import dialog from '@/components/dialog/store';
import toasts from '@/components/toasts/store';
import system from './modules/system';
import chat from '@/modules/chat/store';
import monitoring from '@/modules/monitoring/store';
import user from '@/modules/users/store';
import layout from '@/modules/layout/store';
import localization from '@/modules/localization/store';
import setting from '@/modules/setting/store';
import slide from '@/modules/slideshow/store';
import files from '@/modules/fileManager/store';
import multimedia from '@/modules/multimedia/store/multimedia';
import mediaBlock from '@/modules/multimedia/store/mediablock';
import screenshare from '@/modules/multimedia/store/screenshare';

// TODO fins a way to define proper type
const store = createStore<any>({
  state: () => {},
  getters: {},
  mutations: {},
  actions: {},
});
// phpstorm not detecting modules without this :(
store.registerModule('user', user);
store.registerModule('setting', setting);
store.registerModule('dialog', dialog);
store.registerModule('toasts', toasts);
store.registerModule('system', system);
store.registerModule('chat', chat);
store.registerModule('layout', layout);
store.registerModule('localization', localization);
store.registerModule('slide', slide);
store.registerModule('files', files);
store.registerModule('monitoring', monitoring);
store.registerModule('media', mediaBlock);
store.registerModule('rtc', multimedia);
store.registerModule('screenshare', screenshare);

export default store;

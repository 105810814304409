import type { streaming } from '@back/room-sdk';

interface AudioSRC {
  sessionId: string;
  id: string;
  src: object;
}

interface AudioStatus {
  src: string;
  loading: boolean;
}

interface State {
  audioSRC: AudioSRC;
  webcams: { [sessionId: string]: streaming.StreamInfo };
  audioStatus: AudioStatus;
  micStatus: {
    active: boolean;
    loading: boolean;
  };
  speakerStatus: {
    active: boolean;
    loading: boolean;
  };
  webcamStatus: {
    active: boolean;
    loading: boolean;
  };
  screenStatus: {
    active: boolean;
    loading: boolean;
    access: boolean;
  };
}

export default {
  namespaced: true,
  state: {
    // TODO use map
    audioSRC: {},
    webcams: {},
    speakerStatus: {
      active: true,
      loading: false,
    },
    micStatus: {
      active: false,
      loading: false,
    },
    webcamStatus: {
      active: false,
      loading: false,
    },
    showWebcamStatistics: false,
  },
  getters: {
    getAudioSRC: (state: State) => Object.values(state.audioSRC),
    getMicSource: (state: State) => (sessionId) => state.audioSRC[sessionId],
    getWebcams: (state: State) => Object.values(state.webcams),
    getWebcamById: (state: State) => (clientId) => state.webcams[clientId],
    getMicStatus: (state: State) => state.micStatus,
    getSpeakerStatus: (state: State) => state.speakerStatus,
    getWebcamStatus: (state: State) => state.webcamStatus,
    getScreenStatus: (state: State) => state.screenStatus,
  },
  mutations: {
    setWebcam: (state: State, streamInfo: streaming.StreamInfo) => {
      state.webcams[streamInfo.sessionId] = streamInfo;
    },
    deleteWebcamStream: (state: State, clientId: string) => {
      if (state.webcams[clientId]) {
        delete (state.webcams[clientId] as any).mediaStream;
      }
    },
    deleteVideoSrc: (state: State, clientId: string) => {
      delete state.webcams[clientId];
    },
    setWebcamStatus: (state: State, data: { active: boolean; loading: boolean; access: boolean }) => {
      Object.assign(state.webcamStatus, data);
    },
    setSpeakerStatus: (state: State, data: { active: boolean; loading: boolean }) => {
      Object.assign(state.speakerStatus, data);
    },
    setMicStatus: (state: State, data: { active: boolean; loading: boolean; access: boolean }) => {
      Object.assign(state.micStatus, data);
    },
  },
  actions: {
    deleteAudioSrc({ state }, sessionId) {
      const source = state.audioSRC[sessionId];
      delete state.audioSRC[sessionId];
      const count = Object.keys(state.audioSRC).length;
      return { source, count };
    },
    setAudioSrc({ state }, { streamInfo, el }: { streamInfo: streaming.StreamInfo; el: HTMLAudioElement }) {
      const { sessionId } = streamInfo;

      state.audioSRC[sessionId] = { streamInfo, el };
    },
    getAudioSrc({ state }) {
      return Object.values(state.audioSRC);
    },
    toggleShowWebcamStatistics({ state }, status?) {
      state.showWebcamStatistics = status ?? !state.showWebcamStatistics;
    },
  },
};

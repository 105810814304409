// TODO move to core package

import { getViewPortSize } from '@/helpers/position';
import UAParser from 'ua-parser-js';
import { ORIENTATION } from '@/helpers/constants';

export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const MOBILE_BREAK_POINT = 768;

export function detectDevice() {
  const { width, height, orientation } = getViewPortSize();
  let device = DeviceType.MOBILE;
  // if (width >= 768) device = DeviceType.TABLET;
  // if (width >= 992) device = DeviceType.DESKTOP;
  // TODO const mainSize = orientation === ORIENTATION.PORTRAIT ? width : height;
  if (width >= MOBILE_BREAK_POINT) device = DeviceType.DESKTOP;

  return { width, height, orientation, type: device };
}

export const Platform = UAParser();
export function isFirefox() {
  return Platform.browser.name.toLowerCase() === 'firefox';
}

export function isChrome() {
  return Platform.browser.name.toLowerCase() === 'chrome';
}

export function isOpera() {
  return Platform.browser.name.toLowerCase() === 'opera';
}

export function isEdge() {
  return Platform.browser.name.toLowerCase() === 'edge';
}

export function isSafari() {
  return ['safari', 'mobile safari'].includes(Platform.browser.name.toLowerCase());
}

export function isAndroid() {
  return Platform.os.name.toLowerCase() === 'android';
}

export function isIOS() {
  return Platform.os.name.toLowerCase() === 'ios';
}
export function isMAC() {
  return ['mac', 'mac os'].includes(Platform.os.name.toLowerCase());
}

// https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device
// TODO needs on update to handle samsung, xiomi, insta, facebook, ...
export function isMobileDevice(ua) {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
}

import { nextTick } from 'vue';
import ChatStatus from '@/modules/chat/helpers/chatStatus';
import type { State, ChatMessage } from '@/modules/chat/helpers/interfaces';
import { shouldHideUsername } from './helpers/util';

const MORE_MESSAGE_COUNT = 5;
const MAX_MESSAGE_CHARACTER_COUNT = 500;
export const MAX_SHORT_MESSAGE_CHARACTER_COUNT = 200;
export const MAX_SHORT_MESSAGE_LINE_BREAKS = 3;

// TODO add namespace
// TODO using store.state without namespace is not working
export default {
  state: (): State => ({
    loading: false,
    messageList: [],
    visibleMessageList: [],
    message: {
      id: null,
      text: null,
      userId: null,
      replyTo: null,
      status: null,
      index: -1,
      sessionId: null,
    },
    isPrivateMode: false,
    pinnedMessages: [],
    disabled: false,
    muted: false,
    mutedUsers: [],
    selectedMessage: null,
    maxVisibleMessages: 100,
    maxMessageCharacterCount: MAX_MESSAGE_CHARACTER_COUNT,
    isMaxMessageCharacterReached: false,
    isAtTheBottom: true,
  }),
  getters: {
    getMessages: (state: State): ChatMessage[] => state.messageList,
    getVisibleMessages: (state: State): ChatMessage[] => state.visibleMessageList,
    getMessage: (state: State) => state.message,
    getIsPrivateMode: (state: State) => state.isPrivateMode,
    getPinnedMessage: (state: State) => state.pinnedMessages,
    getDisabled: (state: State) => state.disabled,
    getMutedUsers: (state: State) => state.mutedUsers,
    getMessageById: (state) => (id) => state.messageList.find((m) => m.id === id),
    getSelectedMessage: (state) => state.selectedMessage,
    getMaxMessageCharacterCount: (state: State) => state.maxMessageCharacterCount,
    getIsMaxMessageCharacterReached: (state: State) => state.isMaxMessageCharacterReached,
    getIsAtTheBottom: (state: State) => state.isAtTheBottom,
  },
  mutations: {
    setChatStatus: (state: State, newState: Partial<State>) => {
      Object.assign(
        state,
        newState,
        newState.messageList ? { visibleMessageList: newState.messageList.slice(-state.maxVisibleMessages) } : {},
      );
    },

    addMessage: (state: State, { message, isAtTheBottom }) => {
      message.hideUsername = shouldHideUsername(state.messageList[state.messageList.length - 1], message);

      state.messageList.push(message);
      state.visibleMessageList.push(message);
      if (isAtTheBottom && state.visibleMessageList.length > state.maxVisibleMessages) {
        state.visibleMessageList.splice(0, state.visibleMessageList.length - state.maxVisibleMessages);
      }
    },

    setMessage: (state: State, message: ChatMessage) => {
      state.message =
        message ||
        ({
          text: null,
          id: null,
          sessionId: null,
          userId: null,
          status: ChatStatus.SENDING,
        } as ChatMessage);
    },

    onMuteUser(state: State, user: { userId: string; nickname: string }) {
      state.mutedUsers[user.userId] = user;
    },

    onUnMuteUser(state: State, userId: number) {
      delete state.mutedUsers[userId];
    },

    onUnMuteAllUsers(state: State) {
      state.mutedUsers = {};
    },

    updateMutedUser(state, user) {
      if (state.mutedUsers[user.sessionId]) state.mutedUsers[user.sessionId].nickname = user.nickname;
    },

    onPin: (state: State, id: string) => {
      const index = state.pinnedMessages.findIndex((x) => x === id);
      if (index === -1) {
        state.pinnedMessages.push(id);
        const msg = state.messageList.find((m) => m.id === id);
        if (msg) msg.pinned = true;
      }
    },

    onUnpin: (state: State, id: string) => {
      const index = state.pinnedMessages.findIndex((x) => x === id);
      if (index !== -1) {
        state.pinnedMessages.splice(index, 1);
        const msg = state.messageList.find((m) => m.id === id);
        if (msg) msg.pinned = false;
      }
    },

    onSetIsMaxMessageCharacterReached: (state: State, status: boolean) => {
      state.isMaxMessageCharacterReached = status;
    },
  },
  actions: {
    updateMessage: ({ state }, { id, fields }) => {
      const message = state.messageList.find((m) => m.id === id);
      if (message) {
        Object.keys(fields).forEach((key) => {
          message[key] = fields[key];
        });
      }
    },
    setSelectedMessage: ({ state }, message) => {
      state.selectedMessage = message;
    },
    loadMoreMessages: ({ state }) => {
      const messages = state.messageList;
      const visibleMessages = state.visibleMessageList;
      if (visibleMessages.length < messages.length) {
        const firstMessage = visibleMessages[0];
        const lastIndex = messages.findIndex((m) => m.id === firstMessage.id);
        if (lastIndex === 0) return;
        const start = lastIndex;
        let end = start - MORE_MESSAGE_COUNT;
        if (end < 0) end = 0;
        visibleMessages.unshift(...messages.slice(end, start));

        nextTick(() => document.getElementById(`chat-message-${firstMessage.id}`).scrollIntoView());
      }
    },
    deleteAllMessages: ({ state }) => {
      state.messageList = [];
      state.visibleMessageList = [];
      state.pinnedMessages = [];
    },
    resetPinnedMessages: ({ state }) => {
      const pins = [...state.pinnedMessages];
      state.pinnedMessages = [];
      // TODO Why nectTick?
      nextTick(() => (state.pinnedMessages = pins));
    },
    setIsAtTheBottom: ({ state }, value) => {
      state.isAtTheBottom = value;
    },
  },
};

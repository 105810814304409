import FileType from '@/helpers/enums/fileType';
import { isNumber, isObject } from '@room/ui.sdk/core/utils/comparison';
import { parsedJwtToken } from '@room/ui.sdk/auth/util';
import { TOKEN_URL_REGEX } from '@room/ui.modules/room/url';
import useLocalization from '@/modules/localization';

// TODO refactor all references and then remove these imports
export * from '@room/ui.sdk/core/utils/comparison';
export * from '@room/ui.sdk/core/utils/file';
export * from '@room/ui.sdk/core/utils/dom';
export * from '@room/ui.sdk/core/utils/time';

export function getTimestamp(millisecond?: number) {
  const time = Date.now();
  if (millisecond) {
    return time;
  }

  return Math.floor(time / 1000);
}

export function isRtlLang(text: string, flagsParam?: string) {
  if (!text) return false;

  const flags = flagsParam || '';

  // arabic-farsi
  const pattern = new RegExp('[\u0600-\u06FF]', flags);
  if (pattern.test(text)) return true;

  // Hebrew 0590 — 05FF
  const pattern2 = new RegExp('[\u0590-\u05FF]', flags);
  if (pattern2.test(text)) return true;

  // Syriac 0700 — 074F
  const pattern3 = new RegExp('[\u0700-\u074F]', flags);
  return pattern3.test(text);
}

export function getRandomId() {
  const number = Number.MAX_SAFE_INTEGER;

  return Math.floor(Math.random() * number);
}

// TODO handle array
export function deepExtend<T = any>(
  target: { [key: string]: any },
  source: {
    [key: string]: any;
  },
): T {
  if (isObject(target) && isObject(source)) {
    const sourceKeys = Object.keys(source);
    sourceKeys.forEach((key: string) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepExtend(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return target as T;
}

export function formatBytes(bytes: number, precision?: number) {
  let localPrecision = precision;
  if (!bytes || !isNumber(bytes) || bytes <= 0) return '0';
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  localPrecision = localPrecision ?? (i < 2 ? 0 : 1);

  return `${(bytes / 1024 ** i).toFixed(localPrecision)} ${units[i]}`;
}

export function formatBitrate(bitrate: number, precision?: number) {
  let localPrecision = precision;
  if (!bitrate || !isNumber(bitrate) || bitrate <= 0) return '0';
  const units = ['b/s', 'kb/s', 'mb/s', 'gb/s'];
  const i = Math.floor(Math.log(bitrate) / Math.log(1024));
  localPrecision = localPrecision ?? (i < 2 ? 0 : 1);

  return `${(bitrate / 1024 ** i).toFixed(localPrecision)} ${units[i]}`;
}

export function stopStream(stream: MediaStream) {
  if (!stream) return;
  if (!stream.getTracks()?.length) return;

  stream.getTracks().forEach((track) => {
    track.stop();
  });
}

export function resumeStream(stream: MediaStream) {
  if (!stream) return;
  if (!stream.getTracks()?.length) return;

  stream.getTracks().forEach((track) => {
    track.enabled = true;
  });
}

export function pauseStream(stream: MediaStream) {
  stream.getTracks().forEach((track) => {
    track.enabled = false;
  });
}

export function getExtension(pathOrUrl: string): string {
  return pathOrUrl
    .split(/[#?]/)[0] // remove extra url parts
    .split('.')
    .pop()
    .trim()
    .toLowerCase();
}

export function getFileName(pathOrUrl: string): string {
  return pathOrUrl.substring(0, pathOrUrl.lastIndexOf('.'));
}

const VideoExtRegex = new RegExp(/(mp4|webm|ogv)$/i);
const AudioExtRegex = new RegExp(/(mp3|wav|mpeg|ogg|oga)$/i);
const SlideExtRegex = new RegExp(
  /(zip|pdf|ppt|pptx|odp|png|jpg|jpeg|gif|doc|docx|docm|dot|dotx|html|htm|odt|rtf|wps|txt)$/i,
);

export function getFileType(fileName) {
  const ext = getExtension(fileName);
  if (VideoExtRegex.test(ext)) return FileType.Video;
  if (AudioExtRegex.test(ext)) return FileType.Audio;
  if (SlideExtRegex.test(ext)) return FileType.Slide;

  return FileType.General;
}

export function deepClone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function isMediaUrlPlayable(
  url: string,
  tagName = 'video',
): Promise<{
  playable: boolean;
  error?: any;
}> {
  return new Promise((resolve) => {
    if (tagName === 'slide') {
      const mediaEl = document.createElement('img') as HTMLImageElement;
      mediaEl.addEventListener('load', () => {
        resolve({ playable: true });
      });

      mediaEl.addEventListener('error', (e) => {
        resolve({ playable: false, error: e });
      });
      mediaEl.src = url;
    } else {
      const mediaEl = document.createElement(tagName) as HTMLMediaElement;
      mediaEl.addEventListener('canplay', () => {
        resolve({ playable: true });
      });
      mediaEl.addEventListener('error', (e) => {
        resolve({ playable: false, error: e });
      });
      mediaEl.autoplay = true;
      mediaEl.muted = true;
      mediaEl.setAttribute('playsinline', 'playsinline');
      mediaEl.src = url;
    }
  });
}

export function isValidURL(string) {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
  );

  return res !== null;
}

export function dataURItoBase64(uri: string): string {
  return uri.substring(uri.indexOf('base64,') + 'base64,'.length);
}

export function exitToLoginScreen() {
  window.NotRequiresExitConfirmation = true;
  if (document.referrer && document.referrer.length > 0 && TOKEN_URL_REGEX.test(window.location.href)) {
    window.location.assign(document.referrer);
  } else {
    window.location.href = window.location.href.replace(TOKEN_URL_REGEX, '');
  }
}

/**
 * @deprecated
 */
export const getParsedJwt = parsedJwtToken;

// TODO remove reference and then remove this
export function noop() {
  // empty function
}

export function wait(delay: number) {
  if (!delay) return Promise.resolve();

  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function formatInterval(interval: number) {
  let localInterVal = interval;
  const { t } = useLocalization();
  let granularity = 2;
  let past = 0;
  const separator = t('interval.and');

  if (localInterVal < 0) {
    past = 1;
    localInterVal *= -1;
  }

  let units: object;
  if (localInterVal < 60) {
    units = {
      [t('interval.second')]: 1,
    };
  } else if (localInterVal < 3600) {
    units = {
      [t('interval.minute')]: 60,
    };
  } else {
    units = {
      [t('interval.hour')]: 3600,
      [t('interval.minute')]: 60,
    };
  }

  let output = '';
  const keys = Object.keys(units);
  for (let i = 0, l = keys.length; i < l; i += 1) {
    const key = keys[i];
    const value = units[key];
    if (localInterVal >= value) {
      output += `${output ? separator : ''} ${Math.floor(localInterVal / value)} ${key}`;
      localInterVal %= value;
      granularity -= 1;
    }

    if (granularity === 0) break;
  }

  if (output.length) {
    if (past) output = `${output} ${t('interval.ago')}`;
    else output = t('interval.after') + output;
  } else output = t('interval.lessThanOneSecond');

  return output;
}

export function playSilentAudio() {
  // enable autoplay of audio in iOS/Safari by playing an inaudible sound infinitely
  (document.getElementById('silence') as HTMLAudioElement).play();
}

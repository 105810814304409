import LayoutBlock from '@/modules/layout/models/layoutBlock';
import { deepClone, deepExtend } from '@/helpers/utils';
import type { LayoutBlocks } from '@/modules/layout/models/layoutBlockState';

export interface State {
  // calculated layout based on base,role and local
  layout: LayoutBlocks;
  // base layout for all came from server
  baseLayout: LayoutBlocks;
  // restriction for this role. usage: for now just users visibility where admin can see the user
  // list but guests cannot
  roleLayout: LayoutBlocks;
  // local layout personalized for this user, can hide visible blocks
  localLayout: LayoutBlocks;
  isMobile: boolean;
  enlargeChatBox: boolean;
}

function initLayoutState(name: LayoutBlock, componentName: string, maximize = false, visible = false) {
  PrivateState.baseLayout[name] = {
    visible,
    maximize,
    component: name,
    enabled: true,
    componentName,
    col: 0,
    order: 0,
  };
}

function defaultState(): State {
  const state = {
    layout: {},
    localLayout: {},
  } as State;

  // initLayoutState(LayoutBlock.TOOLBAR, 'TheToolBar', true, true);
  initLayoutState(LayoutBlock.MEDIA, 'MediaBlock');
  initLayoutState(LayoutBlock.WEBCAM, 'VideoGrid');
  initLayoutState(LayoutBlock.USER, 'Users');
  initLayoutState(LayoutBlock.CHAT, 'Chat');
  initLayoutState(LayoutBlock.WHITEBOARD, 'Whiteboard', true);
  initLayoutState(LayoutBlock.SLIDESHOW, 'TheSlideshow', true);
  initLayoutState(LayoutBlock.SCREEN_SHARE, 'ScreenShare', true);

  state.layout = deepClone(PrivateState.baseLayout);

  return state;
}

const PrivateState = {
  baseLayout: {},
  roleLayout: {},
};

export default {
  namespaced: true,
  state: defaultState(),
  actions: {
    updateLayout({ state }) {
      state.layout = deepExtend(deepClone(PrivateState.baseLayout), PrivateState.roleLayout);
    },
    async setBaseLayout({ dispatch }, layout: Partial<LayoutBlocks>) {
      PrivateState.baseLayout = deepExtend(PrivateState.baseLayout, layout) as LayoutBlocks;
      await dispatch('updateLayout');
    },
    // this is only used to restrict access to some modules for some roles
    // so we only need to keep this if the value is false
    async setRoleLayout({ state, dispatch }, { block, visible }) {
      if (!visible) PrivateState.roleLayout[block] = { visible: false };
      else delete PrivateState.roleLayout[block];
      await dispatch('updateLayout');
    },
    async toggleEnabled({ dispatch }, { name, enabled }) {
      // TODO not using this anymore, just visible
      PrivateState.baseLayout[name].enabled = true;
      PrivateState.baseLayout[name].visible = enabled;
      await dispatch('updateLayout');
    },
  },
};

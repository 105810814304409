import type { ROLE } from '@/modules/accessControl/roles';
import { BOARD_TYPE } from '@/modules/whiteboard/boardType';
import type { ClientsVisibility } from '@back/room-sdk/dist/packages/models/src/room/user';

export enum UserDeviceStatus {
  OFF = 0,
  ON = 1,
  PENDING = 2,
  HIDDEN = 3,
  ACTIVE = 4,
}

export enum UserAccessStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  PENDING = 'pending',
}

export enum UserDevices {
  MIC = 'mic',
  WEBCAM = 'webcam',
  DESKTOP = 'screen',
  HAND = 'hand',
}

const BOARD: unique symbol = Symbol(BOARD_TYPE.BOARD);

export interface UserState {
  [UserDevices.MIC]: number;
  [UserDevices.WEBCAM]: number;
  [UserDevices.DESKTOP]: number;
  [UserDevices.HAND]: number;
  [BOARD]: number;
  recording: boolean;
  recordingCloud: boolean;
}

export interface User {
  id: string;
  sessionId: string;
  tabId: string;
  username: string;
  userId: string;
  role: ROLE;
  originalRole: string;
  visible: boolean;
  state: UserState;
  nickname: string;
  platform: any;
  isDisconnected: boolean;
  joinTime: number;
  gender: number;
  ip: string;
  container: string;
  isMobileDevice: boolean;
  faded: boolean;
  isMeAllowedToChangeDeviceStatus: boolean;
}

export interface UserRepo {
  [id: string]: User;
}

export interface SortedUserRepo {
  [category: string]: UserRepo;
}

export interface UserStoreState {
  me: string;
  isPublic: boolean;
  users: UserRepo;
  uiListLight: User[];
  handsUpList: User[];
  activeList: User[];
  searchable: boolean;
  usersCount: number;
  visibility: ClientsVisibility;
  searchKeyword: string;
  filterType: string;
  allUsersStatus: number;
  activeStateCount: number;
}

export enum ListFilterType {
  ANY = 'any',
  NONE = 'none',
  HAND = 'hand',
}

export enum AllUsersStatus {
  UNKNOWN,
  CAN_LOAD,
  LOADING,
  LOADED,
}

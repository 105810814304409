import EventEmitter from '@/helpers/eventEmitter';

export abstract class Service extends EventEmitter {
  public static readonly Name: string;
}

const repo = new Map<string, Service>();

/**
 * @deprecated use the services directly
 */
class ServiceRepo {
  static set(name: string, service: any) {
    repo.set(name, service);
  }

  /**
   * @deprecated use the services directly
   * @param service
   */
  static get<T extends Service>(service: new (...args) => T): T {
    return repo.get(service['Name']) as T;
  }
}

/**
 * @deprecated
 */
export default ServiceRepo;

import type FileType from '@/helpers/enums/fileType';

interface FileStorageInfo {
  diskQuota: number;
  diskUsage: number;
  maxUploadSize: number;
}

interface EditInfo {
  id: string;
  title: string;
  downloadable: number | boolean;
}

export interface RoomFile {
  id: string;
  type: FileType;
  title: string;
  fileSize: string;
  url: string;
  downloadable: number | boolean;
  slides?: Record<number, string>;
  slidesCount?: number;
}

interface UploadedLink {
  type: FileType;
  link: string;
}

interface State {
  files: RoomFile[];
  storageInfo: FileStorageInfo;
  fileToken: string;
  isFileUploaded: boolean;
  isFileOnProgress: boolean;
  recentlyUploadedLink: UploadedLink[];
  selectedFile: null;
  selectedFileInList: null;
}

export default {
  namespaced: true,
  state: {
    files: [],
    storageInfo: {},
    fileToken: null,
    isFileUploaded: false,
    isFileOnProgress: false,
    recentlyUploadedLink: [],
    selectedFile: null,
    selectedFileInList: null,
  },
  getters: {
    getFiles: (state: State) => state.files,
    getStorageInfo: (state: State) => state.storageInfo,
    getFileToken: (state: State) => state.fileToken,
    getIsFileUploaded: (state: State) => state.isFileUploaded,
    getIsFileOnProgress: (state: State) => state.isFileOnProgress,
    getRecentlyUploadedLink: (state: State) => state.recentlyUploadedLink,
    getSelectedFile: (state: State) => state.selectedFile,
    getSelectedFileInList: (state: State) => state.selectedFileInList,
  },
  mutations: {
    addFile: (state: State, data) => {
      state.files.unshift(data);
    },
    setFiles: (state: State, data: RoomFile[]) => {
      state.files = [...data];
    },
    setSelectedFile: (state: State, data: null) => {
      state.selectedFile = data;
    },
    setSelectedFileInList: (state: State, data: null) => {
      state.selectedFileInList = data;
    },
    setFileToken: (state: State, data: string) => {
      state.fileToken = data;
    },
    setIsFileUploaded: (state: State, data: boolean) => {
      state.isFileUploaded = data;
    },
    setIsFileOnProgress: (state: State, data: boolean) => {
      state.isFileOnProgress = data;
    },
    setStorageInfo: (state: State, data: FileStorageInfo) => {
      state.storageInfo = { ...data };
    },
    updateFile: (state: State, data: EditInfo) => {
      const index = state.files.findIndex((item) => item.id === data.id);
      state.files.splice(index, 1, {
        ...state.files[index],
        title: data.title,
        downloadable: data.downloadable,
      });
    },
    deleteFile: (state: State, id: string) => {
      state.files = state.files.filter((item) => item.id !== id);
    },
    setRecentlyUploadedLink: (state: State, data: UploadedLink) => {
      const index = state.recentlyUploadedLink.findIndex((item) => item.link === data.link);
      if (index < 0) {
        state.recentlyUploadedLink = [...state.recentlyUploadedLink, data];
      }
    },
  },
};

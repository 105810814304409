import type * as RoomTypes from '@back/room-sdk';
import { sessionStorageService } from '@/modules/app/services/storageService';
import CONSTANTS from '@/helpers/constants';
import ConnectionStatus from '@/modules/app/services/models/connectionStatus';
import type { RoomInfo, RoomInfoCollection } from '@room/ui.modules/room/roominfo.model';
import type LoginInfo from '@/modules/room/models/loginInfo';
import type { Module } from '@back/room-sdk';
import type { UsersState } from '@back/room-sdk/dist/packages/models/src/room/user/users-state';

export interface SystemState {
  newClient: RoomTypes.IClient;
  client: RoomTypes.IClient;
  roomInfo: RoomInfo;
  roomInfoCollection: RoomInfoCollection;
  loginInfo: LoginInfo;
  waitingForOperator: boolean;
  numberUsersInQueue: number;
  loginPending: boolean;
  autologin: boolean;
  roomClosingAfter: number;
  loginStatus: string;
  roomStartedTime: number;
  activePage: string;
  roomUrl: {
    room: string;
    customer: string;
    token: string;
  };
  connectionStatus: ConnectionStatus;
  userHasInteractedWithPage: boolean;
  thereIsActiveAudio: boolean;
  roomClosed: boolean;
  selfExited: boolean;
  modules: Record<string, Module>;
  localTimeDiff: number;
  usersState: UsersState;
}

function defaultState(): SystemState {
  return {
    client: null,
    newClient: null,
    roomInfo: null,
    roomInfoCollection: null,
    loginInfo: null,
    waitingForOperator: false,
    numberUsersInQueue: 0,
    loginPending: true,
    autologin: false,
    roomClosingAfter: -1,
    loginStatus: CONSTANTS.LOGIN_STATUS.SUCCESS,
    roomStartedTime: 0,
    activePage: 'Spinner',
    roomUrl: null,
    connectionStatus: ConnectionStatus.Connected,
    userHasInteractedWithPage: false,
    thereIsActiveAudio: false,
    roomClosed: false,
    selfExited: false,
    modules: {},
    localTimeDiff: 0,
    usersState: null,
  };
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getRoomInfo: (state: SystemState) => state.roomInfo,
    getLoginInfo: (state: SystemState) => state.loginInfo,
    getSystem: (state: SystemState): SystemState => state,
    guestLoginEnabled: (state: SystemState): boolean => !!state.roomInfo?.channel?.guestLogin,
    waitingForOperator: (state: SystemState): boolean => state.waitingForOperator,
    getNumberOfUsersInQueue: (state: SystemState): number => state.numberUsersInQueue,
    loginPending: (state: SystemState): boolean => state.loginPending,
    getLoginStatus: (state: SystemState): string => state.loginStatus,
    getAutologin: (state: SystemState): boolean => state.autologin,
    getRoomClosingAfter: (state: SystemState): number => state.roomClosingAfter,
    getAutologinStorageKey: (state: SystemState): string => {
      const { roomInfo } = state;
      if (roomInfo) return `room_${roomInfo.owner.name}_${roomInfo.channel.name}_${CONSTANTS.STORAGE.LOGIN_INFO}`;
      return null;
    },
    getRoomStartedTime: (state: SystemState) => state.roomStartedTime,
    getActivePage: (state: SystemState) => state.activePage,
    getConnectionStatus: (state: SystemState) => state.connectionStatus,
    getUserHasInteractedWithPage: (state: SystemState) => state.userHasInteractedWithPage,
    getThereIsActiveAudio: (state: SystemState) => state.thereIsActiveAudio,
    // getModule: (state: SystemState) => (name: string) => state.modules[name],
  },
  mutations: {
    setSystemInfo: (state: SystemState, newState: Partial<SystemState>) => {
      Object.assign(state, newState);
    },
    clearSystemInfo: (state: SystemState) => {
      state.client = null;
      state.roomInfo = null;
      state.loginInfo = null;
      state.waitingForOperator = false;
      state.numberUsersInQueue = 0;
      state.loginPending = true;
      state.autologin = false;
    },
    setRoomStartedTime: (state: SystemState, roomStartedTime: number) => {
      state.roomStartedTime = roomStartedTime;
    },
  },
  actions: {
    setAutologinStorage({ getters }, data: any) {
      sessionStorageService.setItem(getters.getAutologinStorageKey, data);
    },
    getAutologinStorage({ getters }) {
      sessionStorageService.getItem<{ loginInfo: any; credentials: any }>(getters.getAutologinStorageKey);
    },
    clearAutologinStorage({ getters }) {
      sessionStorageService.removeItem(getters.getAutologinStorageKey);
    },
    setConnectionStatus({ state }, value) {
      state.connectionStatus = value;
    },
    setUserHasInteractedWithPage({ state }) {
      state.userHasInteractedWithPage = true;
    },
    setThereIsActiveAudio({ state }, value) {
      state.thereIsActiveAudio = value;
    },
    // addModule({ state }, { name, module }): Module {
    //   state.modules[name] = module;
    //   return module;
    // },
    // removeModule({ state }, { name }) {
    //   // TODO unload or destroy ??
    //   delete state.modules[name];
    // },
  },
};

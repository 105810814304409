import createSingletonService from '@room/ui.sdk/core/utils/singletonServiceProvider';
import { computed } from 'vue';
import store from '@/modules/app/store';
import { localizationService } from '@/modules/localization/services/localizationService';
import type { Language } from '@room/ui.modules/i18n/language';

function factory() {
  const language = computed<Language>(() => store.getters['localization/getLanguage']);

  return {
    language,
    t: localizationService.i18n.t,
  };
}

export default function useLocalization() {
  return createSingletonService<ReturnType<typeof factory>>(factory);
}

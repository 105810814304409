import { parseRoomUrl } from '@room/ui.modules/room/url';
import { DEFAULT_LANGUAGE, SupportedLocales } from '@/configs/localization';
import storageService from '@room/ui.sdk/core/services/storage';
import { deepClone } from '@/helpers/utils';

export const STORAGE_KEY = 'app_localization_locale';

export interface Language {
  locale: string;
  direction: string;
  name: string;
}

// export default function getBrowserLocale(options = {}) {
//   const defaultOptions = {
//     countryCodeOnly: true,
//   };
//   const opt = { ...defaultOptions, ...options };
//   const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
//
//   if (!navigatorLocale) {
//     return null;
//   }
//
//   return opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();
// }

export function detectLocale(): string {
  // TODO not sure if to use this or not, my own browser locale is english
  // maybe it would be better to autodetect wth location?
  // this should be discussed
  // if (!locale)
  //   locale = getBrowserLocale();
  // ToDO use server side magic to detect user languages based on IP

  const storedLocale = storageService.getItem(STORAGE_KEY);
  const { locale } = parseRoomUrl();

  return locale ?? storedLocale ?? DEFAULT_LANGUAGE.locale;
}

export function setupDom(language: Language) {
  const html = document.querySelector('html');
  if (html) html.setAttribute('lang', language.locale);
  ['rtl', 'ltr'].forEach((dir) => document.body.classList.remove(dir));
  document.body.classList.add(language.direction);
}

export function findLanguage(locale: string): Language {
  return SupportedLocales.find((x) => x.locale === locale) ?? deepClone(DEFAULT_LANGUAGE);
}

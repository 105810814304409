import { createI18n } from 'vue-i18n';
import type { VueI18n } from 'vue-i18n';
import type { I18n } from 'vue-i18n';
import { DEFAULT_LANGUAGE, SupportedLocales } from '@/configs/localization';
import type { Language } from '@room/ui.modules/i18n/language';
import { findLanguage, detectLocale, setupDom, STORAGE_KEY } from '@room/ui.modules/i18n/language';
import CONSTANTS from '@/helpers/constants';
import { deepClone } from '@/helpers/utils';
import { Service } from '@/modules/app/services/serviceRepo';
import store from '@/modules/app/store';
import storageService from '@room/ui.sdk/core/services/storage';
import { getGlobalLanguage, saveGlobalLanguage } from '@room/ui.modules/i18n';

const {
  MODULES: {
    LOCALIZATION: {
      GETTERS: { GET_LANGUAGE },
      ACTIONS: { SET_LANGUAGE },
    },
  },
} = CONSTANTS;

const LOADED_LOCALES: string[] = [];

export default class LocalizationService extends Service {
  public static readonly Name = 'localization';

  private i18nInstance: I18n;

  public init(app) {
    const language = getGlobalLanguage();
    this.i18nInstance = createI18n({
      locale: language.locale,
      fallbackLocale: 'en',
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      globalInjection: true,
    });
    app.use(this.i18nInstance);

    this.setLanguage(language).then(() => this.emit('ready'));
  }

  public async setLanguage(language: Language, saveToStorage = false) {
    const localLanguage = SupportedLocales.find((x) => x.locale === language.locale) ?? deepClone(DEFAULT_LANGUAGE);
    this.i18n.locale = localLanguage.locale;
    if (saveToStorage) storageService.setItem(STORAGE_KEY, localLanguage.locale);

    saveGlobalLanguage(localLanguage);
    setupDom(localLanguage);

    await this.loadLocaleMessages(localLanguage.locale);
    this.saveToStore(localLanguage);
  }

  private async loadLocaleMessages(locale: string) {
    if (LOADED_LOCALES.includes(locale)) return Promise.resolve();

    const messages = await import(`../../../locales/${locale}.json`);

    this.i18n.setLocaleMessage(locale, messages.default);

    LOADED_LOCALES.push(locale);

    return Promise.resolve();
  }

  public get language(): Language {
    return store.getters[GET_LANGUAGE];
  }

  private saveToStore(language: Language) {
    store.commit(SET_LANGUAGE, language);
  }

  public get i18n(): VueI18n {
    return this.i18nInstance.global as VueI18n;
  }
}

/**
 * Preferred usage
 * TODO switch all usage to direct import
 */
export const localizationService = new LocalizationService();

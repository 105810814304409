export interface Slide {
  downloadable: boolean;
  fileId: string;
  index: number;
  operatorSessionId: string;
  slideUrl: string;
  slidesCount: number;
  title: string;
  loading: boolean;
  preview: boolean;
  boardEnabled: boolean;
  autoplay: boolean;
  operator: any;
  effect: boolean;
  isSvg?: boolean;
}

interface State {
  slide: Slide;
  slidePreview: Slide;
  busy: boolean;
}

export default {
  namespaced: true,
  state: {
    slide: {} as Slide,
    slidePreview: {} as Slide,
    busy: false,
  },
  getters: {
    getSlide: (state: State): Slide => state.slide,
    getSlidePreview: (state: State): Slide => state.slidePreview,
  },
  mutations: {
    setSlide: (state: State, newState: Partial<State>) => {
      Object.assign(state.slide, newState);
    },
    setSlidePreview: (state: State, newState: Partial<State>) => {
      Object.assign(state.slidePreview, newState);
    },
    setBusy: (state: State, busy: boolean) => {
      state.busy = busy;
    },
  },
};

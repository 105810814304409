import { setStyles } from '@/helpers/dom';
import { ORIENTATION } from '@/helpers/constants';

export enum POSITION {
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  TOP_CENTER = 'top-center',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  BOTTOM_CENTER = 'bottom-center',
  CENTER_CENTER = 'center-center',
  CENTER_START = 'center-start',
  CENTER_END = 'center-end',
}

export function getViewPortSize() {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = e.clientWidth || w.innerWidth || g.clientWidth;
  const y = e.clientHeight || w.innerHeight || g.clientHeight;

  return { width: x, height: y, orientation: x > y ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT };
}

function getPixelValue(value) {
  return value === null ? 'auto' : `${value}px`;
}

export function setStaticPosition(el: HTMLElement, position: string, parent?: HTMLElement, opt = {}) {
  const options = Object.assign(opt, { minLeft: 0, minTop: 0 });

  const viewPortSize = parent ? parent.getBoundingClientRect() : getViewPortSize();

  const w = el.offsetWidth;
  const h = el.offsetHeight;
  let top = null;
  let left = null;
  let right = null;
  let bottom = null;
  const pos = position.split('-');

  switch (pos[0]) {
    case 'top':
      top = options.minTop;
      break;
    case 'center':
      top = (viewPortSize.height - h) / 2;

      if (top < options.minTop) top = options.minTop;

      break;
    case 'bottom':
      bottom = options.minTop;
      break;
  }
  switch (pos[1]) {
    case 'left':
      left = options.minLeft;
      break;
    case 'center':
      left = (viewPortSize.width - w) / 2;

      // eslint-disable-next-line no-multi-assign
      if (left < options.minLeft) right = left = options.minLeft;
      break;
    case 'right':
      right = options.minLeft;
      break;
  }

  setStyles(el, {
    top: getPixelValue(top),
    left: getPixelValue(left),
    right: getPixelValue(right),
    bottom: getPixelValue(bottom),
  });

  return el;
}

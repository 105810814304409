class StorageWrapper {
  // eslint-disable-next-line
  constructor(private storage: Storage, public keyPrefix = '') {}

  setItem(key: string, data: any) {
    this.storage.setItem(`${this.keyPrefix}${key}`, JSON.stringify(data));
  }

  getItem<T = string>(key: string, defaultValue?: T): T {
    let obj = defaultValue ?? null;
    const item = this.storage.getItem(`${this.keyPrefix}${key}`);
    if (item !== null) {
      try {
        obj = JSON.parse(item);
      } catch (e) {
        obj = item as unknown as T;
      }
    }

    return obj as T;
  }

  removeItem(key: string) {
    this.storage.removeItem(`${this.keyPrefix}${key}`);
  }

  hasItem(key: string): boolean {
    return this.storage.getItem(key) !== null;
  }
}

const storageService = new StorageWrapper(localStorage);
const sessionStorageService = new StorageWrapper(sessionStorage);

export default storageService;
export { sessionStorageService, StorageWrapper };

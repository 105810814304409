export function stripTags(string: string) {
  if (!string) return '';
  return (
    string
      // replace br with \r\n
      // eslint-disable-next-line no-useless-escape
      .replace(/<br\s*[\/]?>/gi, '\r\n')
      // -- remove P tags but preserve what's inside of them
      // .replace(/<p.*?>/gi, '\r\n')
      // remove tags
      .replace(/(<([^>]+)>)/gi, '')
      // -- get rid of more than 2 multiple line breaks:
      .replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, '\r\n')
      // remove \r\n from start and end of string
      .replace(/(^(\r\n)+|(\r\n)+$)/gm, '')
      // -- get rid of html-encoded characters:
      .replace(/&nbsp;/gi, ' ')
      .replace(/&amp;/gi, '&')
      .replace(/&quot;/gi, '"')
      .replace(/&lt;/gi, '')
      .replace(/&gt;/gi, '')
      // -- get rid of more than 2 spaces:
      .replace(/ +(?= )/g, '')
      .trim()
  );
}

export function addClass(el: HTMLElement, className: string) {
  el.classList.add(className);
}

export function removeClass(el: HTMLElement, className: string) {
  el.classList.remove(className);
}

export function setStyles(el: HTMLElement, styles: Record<string, any>, important = false) {
  Object.entries(styles).forEach(([key, value]) => {
    el.style.setProperty(key, value, important ? 'important' : undefined);
  });
}

export function setAttributes(el: globalThis.HTMLElement, attrs: Record<string, any>) {
  Object.entries(attrs).forEach(([key, value]) => {
    el.setAttribute(key, value);
  });
}

export function createElement<T = HTMLElement>(
  tag: string,
  attrs?: Record<string, any>,
  styles?: Record<string, any>,
): T {
  const el = document.createElement(tag);
  if (attrs) setAttributes(el, attrs);
  if (styles) setStyles(el, styles);

  return el as any;
}

export function makeElementUnselectable(element: HTMLElement) {
  // @ts-ignore
  if (typeof element.onselectstart !== 'undefined') {
    // @ts-ignore
    element.onselectstart = function falseFunction() {
      return false;
    };
  }

  const styles = {
    '-webkit-user-select': 'none' /* Safari */,
    '-ms-user-select': 'none' /* IE 10 and IE 11 */,
    'user-select': 'none' /* Standard syntax */,
  };
  setStyles(element, styles);

  if (typeof (element as any).unselectable === 'string') {
    (element as any).unselectable = 'on';
  }

  return element;
}

export function htmlToDom<T = HTMLElement>(html: string): T {
  const wrapMap: { [key: string]: any[] } = {
    option: [1, '<select multiple="multiple">', '</select>'],
    legend: [1, '<fieldset>', '</fieldset>'],
    area: [1, '<map>', '</map>'],
    param: [1, '<object>', '</object>'],
    thead: [1, '<table>', '</table>'],
    tr: [2, '<table><tbody>', '</tbody></table>'],
    col: [2, '<table><tbody></tbody><colgroup>', '</colgroup></table>'],
    td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],
    body: [0, '', ''],
    _default: [1, '<div>', '</div>'],
  };
  wrapMap.optgroup = wrapMap.option;
  wrapMap.tbody = wrapMap.tfoot = wrapMap.colgroup = wrapMap.caption = wrapMap.thead;
  wrapMap.th = wrapMap.td;
  const match = /<\s*\w.*?>/g.exec(html);
  let element = document.createElement('div');
  if (match != null) {
    const tag = match[0].replace(/</g, '').replace(/>/g, '').split(' ')[0];
    const map = wrapMap[tag] || wrapMap._default;
    html = map[1] + html + map[2];
    element.innerHTML = html;
    // Descend through wrappers to the right content
    let j = map[0] + 1;
    while (j--) {
      // @ts-ignore
      element = element.lastChild;
    }
  } else {
    element.innerHTML = html;
    // @ts-ignore
    element = element.lastChild;
  }

  return element as unknown as T;
}

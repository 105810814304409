import type { RoleRepository } from '@room/ui.sdk/accessControl/interfaces';

export enum ROLE {
  Guest = 'Guest',
  Member = 'Member',
  Presenter = 'Presenter',
  Operator = 'Operator',
  Admin = 'Admin',
  Owner = 'Owner',
  Support = 'Support',
  Agent = 'Agent',
  Recorder = 'Recorder',
}

export const ROLES: RoleRepository = {
  [ROLE.Guest]: { id: ROLE.Guest, name: 'Guest', level: 0 },
  [ROLE.Member]: { id: ROLE.Member, name: 'Member', level: 1 },
  [ROLE.Agent]: { id: ROLE.Agent, name: 'Agent', level: 1 },
  [ROLE.Presenter]: { id: ROLE.Presenter, name: 'Presenter', level: 2 },
  [ROLE.Operator]: { id: ROLE.Operator, name: 'Operator', level: 3 },
  [ROLE.Owner]: { id: ROLE.Owner, name: 'Owner', level: 4 },
  [ROLE.Support]: { id: ROLE.Support, name: 'Support', level: 5 },
  [ROLE.Admin]: { id: ROLE.Admin, name: 'Admin', level: 6 },
  [ROLE.Recorder]: { id: ROLE.Recorder, name: 'Recorder', level: 7 },
};

import type { Language } from '@/modules/localization/interfaces';

export default {
  namespaced: true,
  state: {
    language: null,
  },
  getters: {
    getLanguage: (state): Language => state.language,
  },
  mutations: {
    setLocale(state, language: Language) {
      state.language = language;
    },
  },
};

import storageService from '@/modules/app/services/storageService';
import CONSTANTS from '@/helpers/constants';
import { deepClone, deepExtend } from '@/helpers/utils';
import { CameraQuality, CameraResolutions, DEFAULT_VIDEO_QUALITY } from '@/modules/multimedia/consts';

const {
  STORAGE,
  SETTINGS: { DEFAULT_DEVICE },
} = CONSTANTS;

export interface WebcamOptions {
  bgBlur: boolean;
  bgImage: string;
  quality: CameraQuality;
  deviceId: string;
  localMirror: boolean;
  localShow: boolean;
  // useCanvas: boolean;
  useNativeVideoElement: boolean;
}

export const DefaultWebcamOptions = {
  bgBlur: false,
  bgImage: null,
  quality: DEFAULT_VIDEO_QUALITY,
  deviceId: DEFAULT_DEVICE.deviceId,
  localMirror: true,
  localShow: true,
  // useCanvas: false,
  useNativeVideoElement: false,
};
export const webcamOptions: WebcamOptions = deepExtend<WebcamOptions>(
  deepClone(DefaultWebcamOptions),
  storageService.getItem(STORAGE.WEBCAM_OPTIONS),
);

// TODO backward compatibility with the old setting storage system
// TODO remove these in future releases
let isModified = false;
if (storageService.hasItem(STORAGE.BLUR_WEBCAM_BG)) {
  webcamOptions.bgBlur = storageService.getItem(STORAGE.BLUR_WEBCAM_BG, false);
  storageService.removeItem(STORAGE.BLUR_WEBCAM_BG);
  isModified = true;
}

if (storageService.hasItem(STORAGE.WEBCAM_BG_IMAGE)) {
  webcamOptions.bgImage = storageService.getItem(STORAGE.WEBCAM_BG_IMAGE);
  storageService.removeItem(STORAGE.WEBCAM_BG_IMAGE);
  isModified = true;
}

if (storageService.hasItem(STORAGE.VIDEO_QUALITY)) {
  webcamOptions.quality = storageService.getItem(STORAGE.VIDEO_QUALITY);
  storageService.removeItem(STORAGE.VIDEO_QUALITY);

  if (!CameraResolutions[webcamOptions.quality]) {
    webcamOptions.quality = DEFAULT_VIDEO_QUALITY;
  }

  isModified = true;
}

if (storageService.hasItem(STORAGE.VIDEO_DEVICE)) {
  webcamOptions.deviceId = storageService.getItem(STORAGE.VIDEO_DEVICE);
  storageService.removeItem(STORAGE.VIDEO_DEVICE);
  isModified = true;
}

if (storageService.hasItem(STORAGE.SHOW_LOCAL_VIDEO_MIRROR)) {
  webcamOptions.localMirror = storageService.getItem<boolean>(STORAGE.SHOW_LOCAL_VIDEO_MIRROR);
  storageService.removeItem(STORAGE.SHOW_LOCAL_VIDEO_MIRROR);
  isModified = true;
}

if (storageService.hasItem(STORAGE.SHOW_LOCAL_VIDEO)) {
  webcamOptions.localShow = storageService.getItem<boolean>(STORAGE.SHOW_LOCAL_VIDEO);
  storageService.removeItem(STORAGE.SHOW_LOCAL_VIDEO);
  isModified = true;
}

// backward compatibility, we don't have auto quality anymore
if (webcamOptions.quality === CameraQuality.Auto) webcamOptions.quality = DEFAULT_VIDEO_QUALITY;

// if data is loaded from old setting keys update the new setting storage
if (isModified) {
  storageService.setItem(STORAGE.WEBCAM_OPTIONS, deepClone(webcamOptions));
}

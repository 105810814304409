import store from '@/modules/app/store';

export function gotoMessage(id) {
  const originalMessage = document.getElementById(`chat-message-${id}`);
  if (originalMessage) {
    originalMessage.scrollIntoView();

    document.querySelectorAll('.chat-highlighted').forEach((el) => {
      el.classList.remove('chat-highlighted');
    });
    // in-case this element is already highlighted, adding the class instantly right after
    // removing it, seems to have no effect
    setTimeout(() => {
      originalMessage.classList.add('chat-highlighted');
      setTimeout(() => {
        originalMessage.classList.remove('chat-highlighted');
      }, 1500);
    });
  }
}

export function shouldHideUsername(
  prevMsg: { time: number; userId: string },
  currentMsg: { time: number; userId: string },
) {
  if (!prevMsg) return false;

  const currentMsgDate = currentMsg.time ? new Date(currentMsg.time - store.state.system.localTimeDiff) : new Date();

  // check if previous message belongs to user
  const prevMsgDate = new Date(prevMsg.time - store.state.system.localTimeDiff);

  return (
    prevMsg.userId === currentMsg.userId &&
    currentMsgDate.getHours() === prevMsgDate.getHours() &&
    currentMsgDate.getMinutes() === prevMsgDate.getMinutes()
  );
}

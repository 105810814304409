import UAParser from 'ua-parser-js';
import { BrowserSupportType } from '@/browser-support/types';
import type { SupportedBrowsers } from '@/browser-support/types';
import { init } from '@sentry/browser';
import type { BrowserOptions } from '@sentry/browser';
import SentryConfig from '@/configs/sentry';
import { captureException } from '@sentry/vue';
import UnsupportedBrowserException from '@/browser-support/UnsupportedBrowserException';

// print app and sdk version
console.log(
  `%cEnv: ${import.meta.env.MODE}
%cApp: ${APP_VERSION}
%cSDK: ${ROOM_SDK_VERSION}`,
  'font-size:20px;color:green',
  'font-size:20px;color:red',
  'font-size:20px;color:orange',
);

// run npx browserslist to show the all supported browsers
export const supportedBrowsers: SupportedBrowsers = {
  firefox: ['Firefox', 60, 'https://www.mozilla.org/en-US/firefox'],
  chrome: ['Chrome', 60, 'https://www.google.com/chrome'],
  opera: ['Opera', 47, 'https://www.opera.com/download'],
  safari: ['Safari', 12.1, 'https://support.apple.com/downloads/safari'],
  samsung: ['Samsung', 8, 'https://www.samsung.com/us/support/owners/app/samsung-internet'],
  edge: ['Edge', 60, 'https://www.microsoft.com/en-us/edge'],
};

let unsupportedBrowserErrorMessage;
function isBrowserSupported(platform: UAParser.IResult): BrowserSupportType {
  const verParts = platform.browser.version.split('.');
  const version = parseFloat(`${verParts[0]}.${verParts[1]}`);
  const browser = platform.browser.name;
  const os = platform.os.name.toLowerCase();
  const engineVersion = Number(platform.engine.version.split('.').shift());
  const engine = platform.engine.name;

  if (
    os === 'android' &&
    ['Facebook', 'Instagram'].includes(browser) &&
    engine === 'Blink' &&
    engineVersion >= supportedBrowsers.chrome[1]
  )
    return BrowserSupportType.Supported;

  // anything other than safari is not supported in ios
  if (os === 'ios' && !/safari/i.test(browser)) return BrowserSupportType.Unsupported;

  if (/edge/i.test(browser) && version >= supportedBrowsers.edge[1]) return BrowserSupportType.Supported;

  // Version 9.5 uses Chrome/61.0.3163 => https://user-agents.net/browsers/miui-browser
  if (os === 'android' && /MIUI Browser/i.test(browser) && version >= 9.5) return BrowserSupportType.Supported;

  const browsers = Object.values(supportedBrowsers);
  for (let i = 0, l = browsers.length; i < l; i++) {
    const [name, v] = browsers[i];
    const regex = new RegExp(name.toString(), 'gi');
    if (browser.match(regex)) return version < v ? BrowserSupportType.Outdated : BrowserSupportType.Supported;
  }

  unsupportedBrowserErrorMessage = `Browser is not supported. OS:[${os}], Browser:[${browser}], Version:[${version}], User Agent:[${platform.ua}]`;
  console.warn(unsupportedBrowserErrorMessage);

  // ignore for known browsers
  if (!['android_Android Browser_4'].includes(`${os}_${browser}_${version}`)) {
    captureException(new UnsupportedBrowserException(unsupportedBrowserErrorMessage));
  }

  return BrowserSupportType.Unsupported;
}

const platform = UAParser();
const browserSupport = isBrowserSupported(platform);

if (browserSupport === BrowserSupportType.Supported) {
  import('./app.main');
} else {
  const os = platform.os.name.toLowerCase();
  let browsers = { ...supportedBrowsers } as any;
  if (os === 'ios') {
    browsers = { safari: supportedBrowsers.safari };
  } else if (os === 'android') {
    delete browsers.safari;
  } else if (os === 'os x') {
    delete browsers.samsung;
  } else {
    delete browsers.safari;
    delete browsers.samsung;
  }
  if (import.meta.env.VITE_SENTRY_DSN) init(SentryConfig as BrowserOptions);
  if (unsupportedBrowserErrorMessage) captureException(new UnsupportedBrowserException(unsupportedBrowserErrorMessage));

  import('./browser-support').then((module) => {
    module.default(browserSupport, browsers);
  });
}

export enum Alert {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface ToastOptions {
  id?: string;
  icon?: any;
  duration?: number;
  dismissible?: boolean;
  showProgressBar?: boolean;
  closeButton?: boolean;
  loading?: boolean;
  type?: Alert;
  content: string | null;
  hasTextCloseButton?: boolean;
  onTextCloseButtonClick?: () => void;
  showDigitTimer?: boolean;
}

export const FEAT_USER_MAX_VISIBLE_USERS = 'user_max_visible_users';
export const FEAT_USER_SORT = 'user_sort'; // alpha|none|default

if (import.meta.env.VITE_SHOW_DEBUG_TOOLS) {
  console.log(
    `-----------------------------------
Debug Tools:
feat:user_max_visible_users=<NUMBER>
feat:user_sort=alpha|none|default

window.USER.addFakeUser(count,role?);
window.USER.handUp(count=1);
window.USER.handDown(all=false);
window.USER.changeRole();
-----------------------------------`,
  );
}

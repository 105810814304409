import debug from 'debug';
import { Debugout } from 'debugout.js';
import { deepClone } from '@/helpers/utils';
import { isString } from '@room/ui.sdk/core/utils/comparison';
import { isArray } from '@room/ui.sdk/core/utils/misc';

const bugout = new Debugout({
  autoTrim: true,
  maxLines: 300,
  quoteStrings: false,
  realTimeLoggingOn: false,
  useLocalStorage: false,
  useTimestamps: true,
  maxDepth: 4,
});

export function logger(name: string) {
  const log = debug(name);
  return (...args: any[]) => {
    // preserving the debug modules usage
    log.call(null, ...args);

    const cloned = deepClone(args);
    //////////////////////
    // scrub chat messages
    //////////////////////
    try {
      if (isArray(cloned)) {
        if (cloned[1] && cloned[1].messages) {
          cloned[1].messages.forEach((m: any) => {
            delete m.text;
            delete m.html;
          });
        }
        if (cloned[0] && isString(cloned[0])) {
          if (cloned[0].includes('chat/message-new')) {
            delete cloned[1].text;
            delete cloned[1].html;
          }
          if (cloned[0]?.includes('chat/new-message')) {
            if (isArray(cloned[1].params)) {
              cloned[1].params.forEach((m: any) => {
                delete m.text;
                delete m.html;
              });
            } else {
              delete cloned[1].params.text;
              delete cloned[1].params.html;
            }
          }
        }
      }
    } catch (e) {
      console.log(cloned);
      console.error(e);
    }
    //////////////////////

    try {
      bugout.log(name, ...cloned);
    } catch (e) {}
  };
}

export function exportConsoleLog() {
  return bugout.getLog();
}

export function scrubLogObject(obj: object) {
  const clone = deepClone(obj);
  if ('token' in clone) delete clone.token;
  if ('tabToken' in clone) delete clone.token;
  if ('password' in clone) delete clone.password;
  return clone;
}

import { deepExtend } from '@/helpers/utils';
import type { SystemState } from '@/modules/app/store/modules/system';

export declare type StateData = {
  mos: number;
  mosClass: string;
  jitter: number;
  jitterClass: string;
  packetLoss: number;
  packetLossClass: string;
  ping: number;
  pingClass: string;
  protocol: string;
  streams: number;
};
export declare type SessionState = {
  enabled: boolean;
  mosClass: string;
  in: StateData;
  out: StateData;
  bytesSent: number;
  bytesReceived: number;
  sendBitrate: number;
  recvBitrate: number;
};
export declare type State = { dataList: { [sessionId: string]: SessionState } };

function defaultState(): SessionState {
  return {
    enabled: false,
    mosClass: 'default',
    in: {
      mos: 0,
      mosClass: 'default',
      jitter: 0,
      jitterClass: 'default',
      packetLoss: 0,
      packetLossClass: 'default',
      ping: 0,
      pingClass: 'default',
      protocol: '',
      streams: 0,
    },
    out: {
      mos: 0,
      mosClass: 'default',
      jitter: 0,
      jitterClass: 'default',
      packetLoss: 0,
      packetLossClass: 'default',
      ping: 0,
      pingClass: 'default',
      protocol: '',
      streams: 0,
    },
    bytesSent: 0,
    bytesReceived: 0,
    sendBitrate: 0,
    recvBitrate: 0,
  };
}

export default {
  namespaced: true,
  state: { dataList: {} } as State,
  getters: {
    getData: (state: State) => (sessionId: string) => state.dataList[sessionId],
  },
  actions: {
    update: ({ state }, { data, sessionId }) => {
      state.dataList[sessionId] = deepExtend(state.dataList[sessionId] ?? defaultState(), data);
      // stupid vuex reactivity
      // state.dataList = { ...state.dataList };
    },
    disable: ({ state }, { sessionId }: { sessionId: string }) => {
      state.dataList[sessionId] = Object.assign(defaultState(), { enabled: false });
    },
    enabled: ({ state }, { sessionId }: { sessionId: string }) => {
      state.dataList[sessionId] = Object.assign(defaultState(), { enabled: false });
    },
  },
};
